<template>
    <div>
        <PageHeader className="galleries" :heading="gallery.title">
            <div class="page-navigation">
                <router-link
                    class="previous"
                    v-if="totalPages > 1"
                    :to="{
                        name : 'Gallery',
                        params: { id: getPreviousPageSlug() }
                    }" />
                <router-link :to="{ name : 'GalleryList' }" class="home" />
                <router-link
                    class="next"
                    v-if="totalPages > 1"
                    :to="{
                        name : 'Gallery',
                        params: { id: getNextPageSlug() }
                    }" />
            </div>
        </PageHeader>
        <div class="gallery-list tile-list" v-if="images.length">
            <a v-for="image in images"
               :href="fancyboxHref(image)"
               :key="image.location"
               :data-caption="image.title"
               :data-type="image.video !== '' ? 'html5video' : 'image'"
               class="tile"
               target="_blank"
               rel="noopener noreferrer"
               data-fancybox="gallery">
                <img :src="image.location" />
            </a>
        </div>
        <Offline v-if="!images.length && isOffline" />
    </div>
</template>

<script>
    import PageHeader from "@/components/PageHeader.vue";
    import Offline from "@/components/Offline.vue";

    export default {
        components: {
            PageHeader,
            Offline
        },
        props: {
            slug: {
                type: String,
                required: true,
                default: ""
            },
            id: {
                type: String,
                required: true
            }
        },
        computed: {
            galleries(){
                return this.$store.state.kiosk.galleries;
            },
            pageIndex(){
                return this.galleries.findIndex( row => row.slug === this.id );
            },
            totalPages(){
                return this.galleries.length;
            },
            gallery(){
                return this.galleries.find( row => row.slug === this.id );
            },
            images(){
                return this.gallery.images.filter( image => {
                    return ( image.video !== "" && this.isOnline ) || image.video === "";
                } );
            },
            isOffline(){
                return this.$store.state.isOffline;
            },
            isOnline(){
                return this.$store.state.isOnline;
            }
        },
        methods:{
            getNextPageSlug() {
                let nextIndex = this.pageIndex + 1;

                if ( nextIndex >= this.totalPages )
                    nextIndex = 0;

                return this.$store.state.kiosk.galleries[nextIndex].slug;
            },
            getPreviousPageSlug() {
                let prevIndex = this.pageIndex - 1;

                if ( prevIndex < 0 )
                    prevIndex = this.totalPages - 1;

                return this.$store.state.kiosk.galleries[prevIndex].slug;
            },
            fancyboxHref( image ) {
                return image.video !== "" ?
                    `https://www.youtube.com/watch?v=${image.video}` :
                    (
                        this.isOnline ?
                            image.src :
                            image.location
                    );
            }
        },
        unmounted(){
            // makes sure to remove slug if leaving path
            // as router handles setting
            document.getElementById( "app" ).classList.remove( this.slug );
        }
    };
</script>
