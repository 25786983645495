import { createRouter, createWebHistory } from "vue-router";

import store from "../store";
import NProgress from "nprogress";

import AreaMap from "../views/AreaMap.vue";
import Brochure from "../views/Brochure.vue";
import Gallery from "../views/Gallery.vue";
import GalleryList from "../views/GalleryList.vue";
import Home from "../views/Home.vue";
import Model from "../views/Model.vue";
import ModelList from "../views/ModelList.vue";
import MyFavorites from "../views/MyFavorites.vue";
import NotFound from "../views/NotFound.vue";
import Page from "../views/Page.vue";
import PageList from "../views/PageList.vue";
import SitePlan from "../views/SitePlan.vue";

const routes = [
    {
        path: "/",
        name: "Home",
        component: Home
    },
    {
        path: "/area-map/",
        name: "AreaMap",
        component: AreaMap
    },
    {
        path: "/site-plan/",
        name: "SitePlan",
        component: SitePlan
    },
    {
        path: "/floorplans/",
        name: "Floorplans",
        component: ModelList,
        props: { slug: "floorplans" }
    },
    {
        path: "/floorplans/:id",
        name: "Floorplan",
        component: Model,
        props: route => ( { id: route.params.id, slug: "floorplans" } )
    },
    {
        path: "/move-in-ready/",
        name: "MoveInReady",
        component: ModelList,
        props: { slug: "inventory" }
    },
    {
        path: "/move-in-ready/:id",
        name: "MoveInReadyHome",
        component: Model,
        props: route => ( { id: route.params.id, slug: "inventory" } )
    },
    {
        path: "/about-community/",
        name: "AboutTheCommunity",
        component: Page,
        props: { slug: "about" }
    },
    {
        path: "/galleries/",
        name: "GalleryList",
        component: GalleryList,
        props: { slug: "galleries" }
    },
    {
        path: "/galleries/:id",
        name: "Gallery",
        component: Gallery,
        props: route => ( { id: route.params.id, slug: "galleries" } )
    },
    {
        path: "/about-kolter/",
        name: "AboutKolter",
        component: PageList,
        props: { slug: "kolter" }
    },
    {
        path: "/about-kolter/:id",
        name: "AboutKolterPage",
        component: Page,
        props: route => ( { id: route.params.id, slug: "kolter" } )
    },
    {
        path: "/lifestyles/",
        name: "Lifestyles",
        component: PageList,
        props: { slug: "lifestyles" }
    },
    {
        path: "/lifestyles/:id",
        name: "LifestylePage",
        component: Page,
        props: route => ( { id: route.params.id, slug: "lifestyles" } )
    },
    {
        path: "/my-favorites/",
        name: "MyFavorites",
        component: MyFavorites,
        beforeEnter: ( to, from ) => {
            const favorites = store.state.favorites.length;
            // if trying to open this without any favorites directly
            // send to home page
            if ( favorites === 0 && !from.name )
                router.push( "/" );
            else if ( favorites === 0 )
                store.dispatch( "openModal", { action:"favorites" } );
            // stop or allow based on favorites
            return favorites > 0 ? true : false;
        }
    },
    {
        path: "/brochure/:id",
        name: "Brochure",
        component: Brochure,
        props: route => ( { id: route.params.id, slug: "favorites" } )
    },
    {
        path: "/:pathMatch(.*)*",
        name: "NotFound",
        component: NotFound
    }
];

const router = createRouter( {
    history: createWebHistory( process.env.BASE_URL ),
    routes
} );


router.beforeEach( async( to, from ) => {
    if ( !store.state.kiosk.id ){
        NProgress.start();
        await store.dispatch( "fetchKiosk" );
        NProgress.done();
    }

    // invalid and send to home
    if ( !store.state.kiosk.id && to.name !== "Home" )
        router.replace( "/" );
} );

export default router;
