export default {
    async loadKioskContentIntoCache( kiosk ){
        const defaultCacheName = "kh-sales-center";
        const theCaches = await caches.keys();
        const app_resources = [];
        let theCache;

        // lets create a cache just for testing
        if ( theCaches.length ){
            theCache = await caches.open( theCaches[0] );
        }
        else {
            theCache = await caches.open( defaultCacheName );
        }

        // setup the request to add everything
        if ( kiosk.welcomeScreenLogo !== "" )
            app_resources.push( kiosk.welcomeScreenLogo );
        if ( kiosk.printLogo !== "" )
            app_resources.push( kiosk.printLogo );

        // about page
        if ( kiosk.about?.images?.length ){
            kiosk.about.images.forEach( entry => {
                app_resources.push( entry.location );
            } );
        }

        // other pages
        for ( let type of ["galleries","kolter","lifestyles"] ){
            if ( kiosk[type]?.length ){
                kiosk[type].forEach( entry => {
                    if ( entry.tileImage !== "" )
                        app_resources.push( entry.tileImage );

                    entry.images.forEach( img => {
                        app_resources.push( img.location );
                    } );
                } );
            }
        }

        // siteplans
        if ( kiosk.siteplans?.length ){
            kiosk.siteplans.forEach( entry => {
                app_resources.push( entry.location );
            } );
        }

        // homes
        for ( let type of ["floorplans","inventory"] ){
            if ( kiosk[type]?.records?.length ){
                kiosk.floorplans.records.forEach( floorplan => {
                    for ( let key of ["elevations","floorplans","renderings"] ){
                        floorplan[key].forEach( entry => {
                            app_resources.push( entry.img );
                            app_resources.push( entry.src );
                            if ( entry.opts?.thumb )
                                app_resources.push( entry.opts.thumb );
                        } );
                    }
                } );
            }
        }

        // do not use addAll because it causes CORS errors
        app_resources.forEach( async( resource ) => {
            // youtube does not allow cors
            if ( resource?.indexOf( "img.youtube.com" ) === -1 )
                theCache.add( resource );
        } );
    },
    async clearCache(){
        const theCaches = await caches.keys();
        // get our caches
        theCaches.forEach( async key => {
            // delete the cache
            await caches.delete( key );
            // recreate the cache
            await caches.open( key );
        } );
    }
};
