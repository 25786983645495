import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";
import KioskService from "@/services/KioskService.js";
import CacheService from "@/services/CacheService.js";
import { isEqual as _isEqual } from "lodash";

const prepareFavorite = ( favorite ) => {
    for ( var key in favorite )
        favorite[key] = key !== "link" ? parseInt( favorite[key] ) : favorite[key];
    return favorite;
};

export default createStore( {
    state: {
        kiosk: {},
        menuOpen: false,
        modalOpen: false,
        welcomeModalOpen: false,
        searchParams : {},
        favorites: [],
        customer : {},
        modal: {},
        lastUpdate : new Date(),
        pendingUpdateDate : null,
        pendingUpdate : false,
        isOnline: true,
        isOffline: false
    },
    mutations: {
        CLEAR_FAVORITES( state ){
            let iframe = document.querySelector( "iframe" );
            // update state
            state.favorites = [];
            // post to iframe
            if ( iframe )
                iframe.contentWindow.postMessage( [], "*" );
        },
        REPLACE_FAVORITES( state, favorites ){
            if ( Array.isArray( favorites ) ){
                let iframe = document.querySelector( "iframe" );
                // update state
                state.favorites = favorites;
                // post to iframe
                if ( iframe ){
                    let _favorites = favorites.filter( favorite => {
                        return favorite.mapentryid !== 0;
                    } ).map( favorite => {
                        return { id: favorite.mapentryid };
                    } );
                    iframe.contentWindow.postMessage( _favorites, "*" );
                }

            }
        },
        RESET_KIOSK( state ) {
            state.kiosk = {};
            state.pendingUpdate = false;
            state.pendingUpdateDate = null;
        },
        SET_CUSTOMER( state, customer ) {
            state.customer = customer;
        },
        SAVE_FAVORITE( state, favorite ){
            const _favorite = prepareFavorite( favorite );
            const existingFavorite = state.favorites.findIndex( _fav => _isEqual( _fav, _favorite ) );
            // send call if logged in and online
            if ( state.customer.id && state.isOnline )
                KioskService.saveFavorite( state.customer.id, _favorite );
            // add or remove
            if ( existingFavorite === -1 )
                state.favorites.push( favorite );
            else
                state.favorites.splice( existingFavorite, 1 );
        },
        SET_KIOSK( state, kiosk ) {
            state.kiosk = kiosk;
            state.lastUpdate = new Date();
            // do not load this on brochure request as
            // it could just be a guest visit
            if ( location.pathname.indexOf( "/brochure/" ) === -1 )
                CacheService.loadKioskContentIntoCache( kiosk );
            state.pendingUpdate = false;
            state.pendingUpdateDate = null;
        },
        SET_MENU_OPEN( state, isOpen ){
            state.menuOpen = isOpen ;
        },
        SET_MODAL( state, modal ) {
            state.modal = modal;
        },
        SET_MODAL_STATE( state, value ) {
            state.modalOpen = value;
        },
        SET_WELCOME_MODAL_STATE( state, value ) {
            state.welcomeModalOpen = value;
        },
        SET_ONLINE_STATE( state, value ) {
            state.isOnline = value;
            state.isOffline = !value;
        },
        SET_SEARCH_PARAMS( state, data ){
            state.searchParams = { ...state.searchParams, ...data };
        },
        SET_PENDING_UPDATE( state, status ) {
            state.pendingUpdate = status;
            state.pendingUpdateDate = status ? new Date() : null;
        },
        TOGGLE_MENU_OPEN( state ){
            state.menuOpen = !state.menuOpen ;
        }
    },
    actions: {
        closeModal( { commit } ) {
            commit( "SET_MODAL_STATE", false );
            commit( "SET_MODAL", {} );
        },
        closeWelcomeModal( { commit } ) {
            commit( "SET_WELCOME_MODAL_STATE", false );
        },
        customerLogin( { commit }, data ){
            commit( "SET_CUSTOMER", data.customer );
            if ( data.favorites )
                commit( "REPLACE_FAVORITES", data.favorites );
            commit( "SET_MODAL", { action : "logged-in" } );
        },
        customerLogout( { commit } ){
            commit( "SET_CUSTOMER", {} );
            commit( "CLEAR_FAVORITES" );
        },
        customerRegistered( { commit }, data ){
            commit( "SET_CUSTOMER", data.customer );
            if ( data.favorites )
                commit( "REPLACE_FAVORITES", data.favorites );
            commit( "SET_MODAL", { action : "registered" } );
        },
        async fetchKiosk( { commit } ) {
            try{
                const apiCall = await KioskService.getData();
                if ( typeof apiCall.data?.kiosk === "object" )
                    commit( "SET_KIOSK", apiCall.data.kiosk );
                else
                    commit( "SET_KIOSK", { "success":false, "message":"API Request Error" } );
            }
            catch( error ){
                if ( error.code === "ECONNABORTED" )
                    commit( "SET_KIOSK", { "success":false, "message":"API Request Timeout" } );
                else
                    commit( "SET_KIOSK", { "success":false, "message":error.message } );
            }
        },
        openModal( { commit }, data ) {
            commit( "SET_MODAL", data );
            commit( "SET_MODAL_STATE", true );
        },
        openWelcomeModal( { commit } ) {
            commit( "SET_WELCOME_MODAL_STATE", true );
        },
        pendingUpdate( { commit }, status ){
            commit( "SET_PENDING_UPDATE", status );
        },
        resetKiosk( { commit } ){
            commit( "RESET_KIOSK" );
        },
        sharedSuccess( { commit } ){
            commit( "SET_MODAL", { action : "shared" } );
        },
        updateOnlineState( { commit }, status ){
            commit( "SET_ONLINE_STATE", status );
        }
    },
    getters: {
        isExistingFavorite : ( state ) => ( favorite ) => {
            return state.favorites.findIndex( _fav => _isEqual( _fav, prepareFavorite( favorite ) ) ) !== -1;
        }
    },
    plugins: [createPersistedState()]
} );
