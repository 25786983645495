<template>
    <HeaderNavigation :kiosk="kiosk" v-if="!isBrochure"></HeaderNavigation>

    <router-view></router-view>

    <footer>
        <FooterNavigation :kiosk="kiosk" v-if="!isBrochure"></FooterNavigation>
        <div id="logo" @click="openWelcomeMessageModal" :class="{ 'with-content' : hasPopupText }">
            <span>Kolter Homes</span>
        </div>
    </footer>

    <div id="forced-reload" v-if="forcedReload">
        <div>
            <i class="fal fa-spinner fa-spin fa-2x"></i><br />
            <h2 class="m-4">KIOSK IS UPDATING</h2>
            <p class="m-0">
                We are sorry if we interrupted you.<br />
                Kiosk will reload when complete and your data will still be right where you left off.
            </p>
        </div>
    </div>

    <transition name="modal-fade" mode="out-in">
        <div id="pending-update" v-if="pendingUpdate">
            There is a pending update.<br />
            <a href="#" @click.prevent="forceUpdate()">Tap here to reload.</a>
        </div>
    </transition>

    <transition name="modal-fade" mode="out-in">
        <Modal v-if="showModal" />
    </transition>

    <transition name="modal-fade" mode="out-in">
        <AdminModal v-if="showAdminModal" @close="showAdminModal = false" />
    </transition>

    <transition name="modal-fade" mode="out-in">
        <WelcomeMessageModal v-if="showWelcomeModal" />
    </transition>
</template>

<script>
    import AdminModal from "@/components/AdminModal.vue";
    import WelcomeMessageModal from "@/components/WelcomeMessageModal.vue";
    import Modal from "@/components/Modal.vue";
    import FooterNavigation from "@/components/FooterNavigation.vue";
    import HeaderNavigation from "@/components/HeaderNavigation.vue";
    import Pusher from "pusher-js";
    import CacheService from "@/services/CacheService.js";
    import KioskService from "@/services/KioskService.js";

    const defaultTitle = "Kolter Homes Sales Center Kiosk";

    // set the default document title value
    document.title = defaultTitle;

    const enabledView = ( _kiosk ) => {
        document.title = _kiosk.community || defaultTitle;
        document.documentElement.classList.add( _kiosk.theme );
        document.getElementById( "app" ).classList.add( "on" );
    };

    export default {
        components: {
            AdminModal,
            Modal,
            FooterNavigation,
            HeaderNavigation,
            WelcomeMessageModal
        },
        data(){
            return {
                showAdminModal : false,
                forcedReload: false,
                onlineInterval : 0
            };
        },
        created(){
            // in case we already have state
            if ( Object.keys( this.$store.state.kiosk ).length )
                enabledView( this.$store.state.kiosk );
            this.subscribe();
            this.adminScreenKeyListener();
            this.setupWindowListeners();
        },
        computed:{
            kiosk() {
                return this.$store.state.kiosk;
            },
            theme() {
                return this.$store.state.kiosk.theme;
            },
            showModal() {
                return this.$store.state.modalOpen;
            },
            showWelcomeModal() {
                return this.$store.state.welcomeModalOpen;
            },
            pendingUpdate() {
                return this.$store.state.pendingUpdate;
            },
            currentRoute() {
                return this.$route.name;
            },
            isBrochure(){
                return this.currentRoute === "Brochure";
            },
            isOffline(){
                return this.$store.state.isOffline;
            },
            hasPopupText(){
                return this.$store.state.kiosk.popup.content !== "" ? true : false;
            }
        },
        watch: {
            kiosk( _kiosk ){
                enabledView( _kiosk );
            }
        },
        methods: {
            // Open Admin Screen by pressing Ctrl+Shift+K
            adminScreenKeyListener(){
                document.addEventListener( "keydown", ( event ) => {
                    if ( event.ctrlKey && event.shiftKey && event.key === "K" ){
                        this.showAdminModal = true;
                        event.stopImmediatePropagation();
                        event.preventDefault();
                    }
                } );
            },
            async forceUpdate(){
                // start back at home on reload
                await this.$router.replace( { name:"Home" } );
                // set the view due to reload state
                this.forcedReload = true;
                // clear the cache
                await CacheService.clearCache();
                // call to get kiosk data
                await this.$store.dispatch( "resetKiosk" );
                // reload the window to be sure
                location.reload();
            },
            notifyOfUpdate(){
                this.$store.dispatch( "pendingUpdate", true );
            },
            pingPongInterval( state, timeout ){
                // always clear the interval first
                clearInterval( this.onlineInterval );
                // set timeout if state is true ( default check is every 60 seconds )
                if ( state )
                    this.onlineInterval = setInterval( this.pingPong, timeout || 60000 );
            },
            setupWindowListeners(){
                window.addEventListener( "online", () => {
                    this.$store.dispatch( "updateOnlineState", true );
                    // restart the checks
                    this.pingPongInterval( true );
                } );

                window.addEventListener( "offline", () => {
                    this.$store.dispatch( "updateOnlineState", false );
                    // stop ping/pong as we are completely disconnected
                    this.pingPongInterval( false );
                } );
                // Network Ping/Pong and Listeners
                this.pingPongInterval( true );
            },
            openWelcomeMessageModal(){
                if ( this.hasPopupText )
                    this.$store.dispatch( "openWelcomeModal" );
            },
            subscribe(){
                // do not subscribe in brochure view
                if ( location.pathname.indexOf( "/brochure/" ) === -1 ) {
                    const _this = this;
                    // Enable pusher logging
                    Pusher.logToConsole = false;

                    const channel_name = "kiosks." + location.hostname.split( "." ).at( 0 );

                    const pusher = new Pusher( "c8e492b1c683d8326bbd", {
                        cluster: "us2"
                    } );

                    const channel = pusher.subscribe( channel_name );

                    channel.bind( "updates", function( data ) {
                        if ( typeof data === "object" && data.type ){
                            switch ( data.type ){
                                case "force":
                                    _this.forceUpdate();
                                    break;
                                default:
                                    _this.notifyOfUpdate();
                                    break;
                            }
                        }
                    } );
                }
            },
            async pingPong(){
                try {
                    const apiCall = await KioskService.ping();
                    // update if we were offline
                    if ( apiCall.status === 200 && this.$store.state.isOffline ){
                        // reset the checks
                        this.pingPongInterval( true );
                        this.$store.dispatch( "updateOnlineState", true );
                    }
                }
                catch( e ){
                    if ( this.$store.state.isOnline ){
                        // speed up the checks
                        this.pingPongInterval( true, 5000 );
                        this.$store.dispatch( "updateOnlineState", false );
                    }
                }
            }
        },
        beforeUnmount(){
            this.pingPongInterval( false );
        }
    };
</script>

<style lang="less">
    @import "./assets/less/_variables.less";
    @import "./assets/less/_normalize.less";
    @import "./assets/less/app.less";
    @import "./assets/less/sidebar.less";
    @import "./assets/less/nav.less";
    @import "./assets/less/home.less";
    @import "./assets/less/page.less";
    @import "./assets/less/floorplan.less";
    @import "./assets/less/maps.less";
    @import "./assets/less/themes.less";
    @import "./assets/less/favorites.less";
    @import "./assets/less/modal.less";
</style>
