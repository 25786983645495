<template>
    <div class="modal-mask">
        <div class="modal-wrapper" id="welcome-message-popup">
            <div class="modal-container">
                <div class="modal-body">
                    <button type="button" class="btn-close" aria-label="Close" @click="closeModal()"></button>
                    <div id="welcome-message-content" v-html="popup.content"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        computed: {
            popup(){
                return this.$store.state.kiosk.popup;
            }
        },
        methods: {
            closeModal(){
                this.$store.dispatch( "closeWelcomeModal" );
            }
        }
    };
</script>
