<template>
    <div class="plan">
        <div class="header">
            <h1>
                {{ model.data.name }}
            </h1>
            <a href="#"
               :data-communityID="communityID"
               :data-modelID="model.data.modelID"
               :data-link="model.data.link"
               data-mapEntryID="0"
               data-favorite="1"
               @click.prevent="$emit('removeFavorite',$event)">
                <i class="fad fa-trash"></i> Delete
            </a>
            <div class="form-check form-switch" @change="$emit('printSet',$event)">
                <label class="form-check-label" :for="`_${model.data.modelID}_model_checkbox`">
                    <i class="fad fa-print"></i> Print
                </label>
                <input class="form-check-input" type="checkbox" :id="`_${model.data.modelID}_model_checkbox`" checked>
            </div>
        </div>

        <div class="floorplans">
            <select v-if="model.floorplans.length > 1" @change="toggleFloorplan($event)">
                <option v-for="(floorplan,index) in model.floorplans"
                        :value="index"
                        :key="index">
                    {{ floorplan.caption || `Floorplan ${index + 1}` }}
                </option>
            </select>

            <div class="floorplan-image" v-for="(floorplan,index) in model.floorplans" :key="index" :class="{ 'on':index === currentStaticIndex }">
                <img :src="floorplan.img" />
            </div>
        </div>

        <div class="details">
            <h4>Details</h4>
            <ul class="detail-list">
                <li>
                    <img src="/svg/model-icons/square-feet-contrast.svg" />
                    {{ formatNumber(model.data.sqftAC) }} Square Feet
                </li>
                <li>
                    <img src="/svg/model-icons/stories-contrast.svg" />
                    {{ model.data.stories + (model.data.stories === 1 ? ' Floor' : ' Floors') }}
                </li>
                <li>
                    <img src="/svg/model-icons/bedrooms-contrast.svg" />
                    {{ model.data.bedrooms }} Bedrooms
                </li>
                <li>
                    <img src="/svg/model-icons/full-baths-contrast.svg" />
                    {{ model.data.bathroom }} Full Baths
                </li>
                <li>
                    <img src="/svg/model-icons/garage-contrast.svg" />
                    {{ model.data.garage }} Car Garage
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            model : {
                type: Object,
                required: true
            },
            communityID: Number
        },
        emits:[
            "removeFavorite",
            "printSet"
        ],
        data(){
            return {
                currentStaticIndex : 0
            };
        },
        methods: {
            formatNumber( value ){
                return new Intl.NumberFormat().format( value );
            },
            toggleFloorplan( event ){
                this.currentStaticIndex = parseInt( event.target.value );
            }
        }
    };
</script>
