import store from "../store";

export default {
    saveFavorite( event ){
        const target = event.target.closest( "[data-favorite]" );

        if ( target ) {
            event.stopImmediatePropagation();
            const favorite = { ...target.dataset };
            store.commit( "SAVE_FAVORITE", favorite );
            this.setButtonState(
                target,
                store.getters.isExistingFavorite( favorite )
            );
        }
    },
    removeFavorite( favorite ){
        store.commit( "SAVE_FAVORITE", favorite );
    },
    clearFavorites( favorite ){
        store.commit( "CLEAR_FAVORITES" );
    },
    checkFavorites( element ){
        element.querySelectorAll( "[data-favorite]" ).forEach( element => {
            this.setButtonState(
                element,
                store.getters.isExistingFavorite( { ...element.dataset } )
            );
        } );
    },
    setButtonState( element, isFavorite ){
        const favClass = "fas",
            notFavClass = "fal",
            icon = element.querySelector( ".fa-heart" ),
            mapListItem = icon.closest( "li[data-id]" );

        icon.classList.remove( isFavorite ? notFavClass : favClass  );
        icon.classList.add( isFavorite ? favClass : notFavClass );

        if ( mapListItem ){
            if ( isFavorite )
                mapListItem.classList.add( "favorite" );
            else
                mapListItem.classList.remove( "favorite" );
        }
    }
};
