<template>
    <div class="accordion" :id="`_${id}`">
        <div class="accordion-item">
            <div class="form-check form-switch ms-auto"  @change="$emit('printSet', $event)">
                <label class="form-check-label" :for="`_${id}_checkbox`">
                    <i class="fad fa-print"></i> Print All {{ title }}
                </label>
                <input class="form-check-input" type="checkbox" :id="`_${id}_checkbox`" checked>
            </div>
            <h2 class="accordion-header" :id="`_${id}_header`">
                <button class="accordion-button d-flex"
                        type="button"
                        data-bs-toggle="collapse"
                        aria-expanded="true"
                        :data-bs-target="`#_${id}_body`"
                        :aria-controls="`_${id}_body`">
                    <span>{{ title }}</span>
                    <span><span class="counter">{{ total }}</span></span>
                </button>
            </h2>
            <div class="accordion-collapse collapse show"
                 :id="`_${id}_body`"
                 :aria-labelledby="`_${id}_header`"
                 :data-bs-parent="`#_${id}`">
                <div class="accordion-body">
                    <slot></slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            id: String,
            title: String,
            total: Number
        },
        emits:[
            "printSet"
        ],
        methods: {
        }
    };
</script>
