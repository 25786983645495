export default {
    date( value ){
        return !( /Invalid|NaN/ ).test( new Date( value ) );
    },
    dateTime( value ){
        return !( /Invalid|NaN/ ).test( new Date( value ) );
    },
    email( value ){
        return ( /^[_a-zA-Z0-9\-]+((\.[_a-zA-Z0-9\-]+)*|(\+[_a-zA-Z0-9\-]+)*)*@[a-zA-Z0-9\-]+(\.[a-zA-Z0-9\-]+)*(\.[a-zA-Z]{2,4})$/i ).test( value );
    },
    float( value ){
        return ( /^[\-+]?[0-9]*\.?[0-9]+$/ ).test( value );
    },
    integer( value ){
        return ( /^\d+$/ ).test( value );
    },
    slug( value ) {
        return ( /^[a-z0-9]{3,}(?:(-|_)[a-z0-9]+)*$/ ).test( value );
    },
    slugWithPeriod( value ) {
        return ( /^[a-z0-9]{2,}(?:(-|\.|_)[a-z0-9]+)*$/ ).test( value );
    },
    // links for class or type validation
    datepicker( value ){
        return this.date( value );
    },
    datetime( value ){
        return this.date( value );
    },
    number( value ){
        return this.integer( value );
    },
    time( value ){
        return this.dateTime( value );
    },
    timepicker( value ){
        return this.dateTime( value );
    },
    username( value ) {
        return this.slugWithPeriod( value );
    }
};
