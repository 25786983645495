<template>
    <div class="modal-mask">
        <div class="modal-wrapper">
            <div class="modal-container">

                <div class="modal-body">
                    <div>
                        <strong>Last Kiosk Update</strong><br />
                        {{ renderDate( lastUpdate ) }}
                    </div>
                    <div v-if="pendingUpdate" class="alert alert-info mt-3 mb-0">
                        There is a pending update from<br />{{ renderDate( pendingUpdateDate ) }}.<br />
                        Click below to process it.
                    </div>
                    <div class="alert alert-success mt-3 mb-0" v-if="updated">
                        <strong>Cache has been reset and Kiosk data has been fetched succesfully.</strong>
                        <small class="d-block">
                            The cache is being reloaded in the background.
                            Based on your internet speed this request could take a few seconds.
                            You can close this view at this point.
                        </small>
                    </div>
                </div>

                <div class="modal-footer justify-content-center">
                    <button type="button" class="btn btn-secondary rounded-0 text-uppercase" aria-label="Close" @click.stop="closeModal()" v-show="!updating">Close</button>
                    <button type="button" class="btn btn-success rounded-0 text-uppercase" aria-label="Force Update" @click.stop="forceUpdate()" v-show="!updating">{{ pendingUpdateDate ? 'Processing Pending Update' : 'Force Update' }}</button>
                    <button type="button" class="btn btn-success rounded-0 w-100 text-uppercase" v-show="updating" disabled>Updating <i class="fal fa-spinner fa-spin"></i></button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import CacheService from "@/services/CacheService.js";

    export default {
        data(){
            return {
                updating: false,
                updated: false
            };
        },
        computed: {
            lastUpdate(){
                return this.$store.state.lastUpdate;
            },
            pendingUpdate() {
                return this.$store.state.pendingUpdate;
            },
            pendingUpdateDate() {
                return this.$store.state.pendingUpdateDate;
            }
        },
        methods: {
            renderDate( theDate ){
                return new Intl.DateTimeFormat( "en-US" ,{
                    dateStyle: "full",
                    timeStyle: "short"
                } ).format( new Date( theDate ) );
            },
            closeModal(){
                this.$emit( "close" );
            },
            async forceUpdate(){
                this.updating = true;
                // clear the cache
                await CacheService.clearCache();
                // call to get kiosk data
                await this.$store.dispatch( "fetchKiosk" );
                // let us know the update is complete
                this.updating = false;
                this.updated = true;
            }
        }
    };
</script>
