<template>
    <div id="footer-nav" v-if="kiosk.id">
        <router-link to="/" class="home">
            <span class="icon"></span>
            <span>Home</span>
        </router-link>
        <router-link to="/area-map/" class="area-map">
            <span class="icon"></span> Area Map
        </router-link>
        <router-link to="/galleries/" class="galleries">
            <span class="icon"></span> Photos &amp; Videos
        </router-link>
        <router-link to="/floorplans/" class="floorplans" v-if="floorplans">
            <span class="icon"></span> Floorplans
        </router-link>
        <router-link to="/move-in-ready/" class="inventory" v-if="inventory">
            <span class="icon"></span> Move In Ready
        </router-link>
        <router-link to="/lifestyles/" class="lifestyles">
            <span class="icon"></span> Lifestyles
        </router-link>
        <router-link to="/about-kolter/" class="kolter">
            <span class="icon"></span> About Kolter
        </router-link>
        <router-link to="/site-plan/" class="site-plan">
            <span class="icon"></span> Site Plan
        </router-link>
        <router-link to="/my-favorites/" class="my-favorites" :class="{ 'has-favorites' : favorites > 0 }">
            <i class="favorites">{{ favorites }}</i>
            <span class="icon"></span> My Favorites
        </router-link>
        <a href="#" :class="{'sign-in': !isLoggedIn, 'sign-out': isLoggedIn, 'disabled': isOffline }" @click.stop="toggleModal()">
            <span class="icon"></span>
            <span v-html="loginButtonText"></span>
        </a>
    </div>
</template>

<script>
    export default {
        props : ["kiosk"],
        computed:{
            menuOpen() {
                return this.$store.state.menuOpen || false;
            },
            favorites() {
                return this.$store.state.favorites.length;
            },
            floorplans() {
                return this.$store.state.kiosk.floorplans.records.length ? true : false;
            },
            inventory() {
                return this.$store.state.kiosk.inventory.records.length ? true : false;
            },
            customer() {
                return this.$store.state.customer;
            },
            isLoggedIn() {
                return this.customer.id ? true : false;
            },
            loginButtonText() {
                return this.isLoggedIn ?
                    `Leave Session <small>${this.customer.firstName}</small>` :
                    "Login / Register";
            },
            isOffline(){
                return this.$store.state.isOffline;
            },
            isBrochure(){
                return this.$route.name === "Brochure";
            }
        },
        methods: {
            toggleModal() {
                if ( this.isOffline )
                    this.$store.dispatch( "openModal", { action:"offline" } );
                else if ( this.isLoggedIn )
                    this.$store.dispatch( "openModal", { action:"log-out" } );
                else
                    this.$store.dispatch( "openModal", { action:"account-state", isModal: true } );
            }
        }
    };
</script>
