<template>
    <div id="home-page" v-if="isValidKiosk">
        <div id="home-image" v-if="welcomeScreenLogo">
            <div>
                <h1>Welcome to</h1>
                <img :src="welcomeScreenLogo" />
            </div>
        </div>
        <div id="home-carousel" v-if="welcomeImages" ref="carousel">
            <div v-for="(image,index) in welcomeImages" :key="image.location" class="slide" :class="{'active':(index === 0)}">
                <img :src="image.location" />
            </div>
        </div>
    </div>
    <Offline v-if="!isValidKiosk" :invalidKiosk="true" />
</template>

<script>
    import Offline from "@/components/Offline.vue";
    export default {
        components:{
            Offline
        },
        data: function() {
            return {
                currentInterval     : 0,
                currentSlide        : 0,
                slides              : null,
                totalSlides         : null
            };
        },
        methods:{
            setMenuState(){
                this.$store.commit( "TOGGLE_MENU_OPEN" );
            }
        },
        computed: {
            welcomeImages() { return this.$store.state.kiosk.welcomeImages; },
            welcomeScreenLogo() { return this.$store.state.kiosk.welcomeScreenLogo; },
            menuOpen() { return this.$store.state.menuOpen || false; },
            isValidKiosk(){ return this.$store.state.kiosk?.id ? true : false; }
        },
        mounted() {
            if ( this.isValidKiosk ) {
                // carousel animation
                this.currentInterval = setInterval( () => {
                    if ( !this.totalSlides ) {
                        this.slides = this.$refs.carousel.querySelectorAll( ".slide" );
                        this.totalSlides = this.slides.length;
                    }
                    // kill interval if 1 slide or less
                    if ( this.totalSlides <= 1 ) {
                        clearInterval( this.currentInterval );
                        return;
                    }
                    const currentSlide = this.slides[this.currentSlide];
                    currentSlide.classList.replace( "active", "previous" );
                    // increase slide
                    this.currentSlide++;
                    if ( this.totalSlides <= this.currentSlide ) {
                        this.currentSlide = 0;
                    }
                    this.slides[this.currentSlide].classList.add( "active" );
                    this.slides[this.currentSlide].addEventListener( "transitionend", () => {
                        currentSlide.classList.remove( "previous" );
                    }, {
                        once: true
                    } );
                }, 8000 );
            }
        },
        beforeUnmount() {
            clearInterval( this.currentInterval );
        }
    };
</script>
