<template>
    <div>
        <div :class="{ 'printing':printing }" v-show="!loading">
            <PageHeader className="brochure" heading="Personalized Brochure" v-if="!invalidBrochure" />

            <div id="favorites-header" v-if="!invalidBrochure">
                <div class="print-all">
                    <i class="fad fa-print"></i> Print All
                    <div class="form-check form-switch form-select-lg" @change="printSet( $event )">
                        <input class="form-check-input" type="checkbox" id="print_all" ref="print_all" checked>
                    </div>
                </div>
                <button type="button" class="custom-btn" ref="printButton" @click.prevent="printFavorites()">
                    <i class="fas fa-print"></i> &nbsp;
                    Print
                </button>
            </div>

            <div class="container favorites-container" ref="favoritesArea" v-if="!invalidBrochure">
                <!-- floorplans -->
                <FavoritePanel title="Floorplans"
                               id="floorplans"
                               :total="floorplans.length"
                               v-if="floorplans.length > 0"
                               @printSet="printSet">
                    <FavoritePanelModelDetail v-for="rec in floorplans"
                                              :key="rec.data.modelID"
                                              :model="rec"
                                              :communityID="communityID"
                                              @printSet="printSet" />
                </FavoritePanel>

                <!-- mir -->
                <FavoritePanel title="Move In Ready"
                               id="mir"
                               :total="mir.length"
                               v-if="mir.length > 0"
                               @printSet="printSet">
                    <FavoritePanelModelDetail v-for="rec in mir"
                                              :key="rec.data.modelID"
                                              :model="rec"
                                              :communityID="communityID"
                                              @printSet="printSet" />
                </FavoritePanel>

                <!-- poi -->
                <FavoritePanel title="Points of Interest"
                               id="poi"
                               :total="poi.length"
                               v-if="poi.length > 0"
                               @printSet="printSet">
                    <div class="d-flex places">
                        <div v-for="place in poi" :key="place.place_id" class="place">
                            <div>
                                <h3>
                                    {{ place.name }}
                                </h3>
                                <small>
                                    {{
                                        place.address.address1 +
                                            ( place.address.address2 !== '' ? ', ' + place.address.address2 : '' )
                                    }}
                                </small>
                            </div>
                        </div>
                    </div>
                </FavoritePanel>
            </div>

            <div v-if="invalidBrochure" class="invalid-brochure">
                <div>
                    <strong>
                        Sorry but there are no favorites in this personalized brochure.
                    </strong>
                    <small>
                        This could be the result of an invalid link or the person that
                        shared this brochure has removed all their favorites.
                    </small>
                </div>
            </div>

            <div id="printing">
                <div>
                    <i class="fal fa-spinner-third fa-spin fa-4x text-white"></i>
                </div>
            </div>
        </div>

        <div id="print-area" ref="printArea"></div>

        <div id="loading-brochure" v-show="loading">
            <div>
                <i class="fad fa-spinner-third fa-spin fa-4x"></i>
            </div>
        </div>
    </div>
</template>

<script>
    import PageHeader from "@/components/PageHeader.vue";
    import FavoritePanel from "@/components/FavoritePanel.vue";
    import FavoritePanelModelDetail from "@/components/FavoritePanelModelDetail.vue";
    import KioskService from "@/services/KioskService.js";

    export default {
        data(){
            return {
                printing: false,
                invalidBrochure: false,
                loading: true,
                favorites : [],
                customer : {}
            };
        },
        props: {
            id: {
                type: String,
                required: true
            }
        },
        components: {
            PageHeader,
            FavoritePanel,
            FavoritePanelModelDetail
        },
        async mounted(){
            try{
                const brochure = await KioskService.getBrochure( this.id );

                if ( brochure.data?.data?.customer ){
                    this.customer   = brochure.data.data.customer;
                    this.favorites  = brochure.data.data.favorites;
                }
                else {
                    this.invalidBrochure = true;
                }
            }
            catch( e ){
                console.error( e );
            }
            finally{
                this.loading = false;
            }
        },
        computed:{
            address(){
                let str = "";
                if ( this.$store.state.kiosk.map?.location?.address ){
                    str += this.$store.state.kiosk.map.location.address.address1 + " " +
                        (
                            this.$store.state.kiosk.map.location.address.address2 !== "" ?
                                this.$store.state.kiosk.map.location.address.address2 + " " :
                                ""
                        ) +
                        this.$store.state.kiosk.map.location.address.city + ", " +
                        this.$store.state.kiosk.map.location.address.stateAbb + " " +
                        this.$store.state.kiosk.map.location.address.zipCode;
                }
                return str;
            },
            homeBuyer() {
                return this.customer?.firstName ?
                    this.customer?.firstName + " " + this.customer?.lastName :
                    "Valued Home Buyer";
            },
            communityName() {
                return this.$store.state.kiosk.community;
            },
            floorplans() {
                const ids = this.favorites
                    .map( entry => parseInt( entry.modelid ) )
                    .filter( id => id !== 0 );

                return this.$store.state.kiosk.floorplans.records.filter( entry => {
                    const id = parseInt( entry.data.modelID );
                    return ids.indexOf( id ) !== -1;
                } );
            },
            mir() {
                const ids = this.favorites
                    .map( entry => parseInt( entry.modelid ) )
                    .filter( id => id !== 0 );

                return this.$store.state.kiosk.inventory.records.filter( entry => {
                    const id = parseInt( entry.data.modelID );
                    return ids.indexOf( id ) !== -1;
                } );
            },
            poi() {
                const ids = this.favorites
                    .map( entry => parseInt( entry.mapentryid ) )
                    .filter( id => id !== 0 );
                const places = [];

                this.$store.state.kiosk.map.places.forEach( category => {
                    category.places.forEach( place => {
                        if ( ids.indexOf( place.id ) !== -1 )
                            places.push( place );
                    } );
                } );
                return places;
            },
            communityID(){
                return this.$store.state.kiosk.communityID;
            },
            coverPageHeading(){
                return this.$store.state.kiosk.printLogo !== ""  ?
                    `<img src="${this.$store.state.kiosk.printLogo}" />` :
                    this.$store.state.kiosk.welcomeScreenLogo !== ""  ?
                        `<img src="${this.$store.state.kiosk.welcomeScreenLogo}" />` :
                        `<h1>${this.communityName}</h1>`;
            }
        },
        methods: {
            formatNumber( value ){
                return new Intl.NumberFormat().format( value );
            },
            printFavorites(){
                this.printing = true;

                this.renderCoverPage();

                this.$refs.favoritesArea.querySelectorAll( ".plan" ).forEach( plan => {

                    if ( plan.querySelector( ".form-check-input" ).checked ) {
                        const _content      = plan.cloneNode( true );
                        const _floorplans   = _content.querySelector( "select" );
                        const _pages        = [ _content ];

                        // this has multiple floorplans
                        if ( _floorplans ){
                            for ( var i = 1; i < _floorplans.options.length; i++ ){
                                _pages.push( _content.cloneNode( true ) );
                            }
                            _pages.forEach( ( page, index ) => {
                                page.querySelector( ".header > h1" ).innerHTML += " : " + _floorplans.options[index].innerText;
                                page.querySelectorAll( ".floorplan-image" ).forEach( ( image, imageIndex ) => {
                                    if ( imageIndex === index )
                                        image.classList.add( "on" );
                                    else
                                        image.classList.remove( "on" );
                                } );
                            } );
                        }

                        _pages.forEach( page => {
                            const container = document.createElement( "div" );
                            container.classList.add( "print-page" );
                            container.innerHTML = `
                            <div class="page-header">
                                <img src="/svg/logos/kolterhomes-logo.svg" />
                                <h1>${this.communityName}</h1>
                            </div>
                            <div class="page-content">
                                ${page.outerHTML}
                            </div>`;
                            this.$refs.printArea.appendChild( container );
                        } );
                    }
                } );

                const poi = this.$refs.favoritesArea.querySelector( "#_poi" );

                if ( poi && poi.querySelector( ".form-check-input" ).checked ) {
                    const _content = poi.querySelector( ".places" ).cloneNode( true );
                    const _page = document.createElement( "div" );
                    _page.classList.add( "print-page" );
                    _page.innerHTML = `
                    <div class="page-header">
                        <img src="/svg/logos/kolterhomes-logo.svg" />
                        <h1>${this.communityName}</h1>
                    </div>
                    <div class="page-content">
                        <h2>Points of Interest</h2>
                        ${_content.outerHTML}
                    </div>`;
                    this.$refs.printArea.appendChild( _page );
                }


                window.onafterprint = () => {
                    this.printing = false;
                    this.$refs.printArea.innerHTML = "";
                };

                setTimeout( ()=> {
                    window.print();
                }, 250 );

            },
            renderCoverPage(){
                this.$refs.printArea.innerHTML = `<div class="print-page cover-page">
                    <div class="page-header">
                        <img src="/svg/logos/kolterhomes-logo.svg" />
                        <h1>${this.communityName}</h1>
                    </div>
                    <div class="page-footer">
                        <img src="/svg/logos/kolterhomes-logo.svg" />
                        <h1>${this.communityName}</h1>
                    </div>
                    <div class="page-content">
                        <h3 class="brochure-heading">
                            <i class="fas fa-heart"></i>
                            <span>Personalized Presentation for</span>
                            <strong>${this.homeBuyer}</strong>
                        </h3>
                        <div>
                            ${this.coverPageHeading}
                            <small>${this.address}</small>
                        </div>
                        <div>
                            Thank you for visiting ${this.communityName}<br />
                            A Kolter Homes Community
                        </div>
                    </div>
                </div>`;
            },
            printSet( event ){
                if ( event.target.id === "print_all" ){
                    this.$refs.favoritesArea.querySelectorAll( "[type=\"checkbox\"]" ).forEach( checkbox => {
                        checkbox.checked = event.target.checked;
                    } );
                    this.$refs.printButton.disabled = !event.target.checked;
                }
                else if ( event.target.id.indexOf( "_model_checkbox" ) !== -1 ){
                    const parent = event.target.closest( ".accordion" ),
                          checkbox = parent.querySelector( ".form-check-input" );
                    if ( !event.target.checked ){
                        this.$refs.print_all.checked = false;
                        checkbox.checked = false;
                        this.updatePrintButtonState();
                    }
                    else {
                        this.updatePrintAllCheckBox( parent.querySelector( ".accordion-body" ), checkbox );
                    }
                }
                else {
                    const parent = event.target.id.replace( "_checkbox","" );
                    this.$refs.favoritesArea.querySelectorAll( `#${parent}_body [type="checkbox"]` ).forEach( checkbox => {
                        checkbox.checked = event.target.checked;
                    } );
                    if ( !event.target.checked ){
                        this.$refs.print_all.checked = false;
                        this.updatePrintButtonState();
                    }
                    else
                        this.updatePrintAllCheckBox();
                }
            },
            updatePrintAllCheckBox( section, checkbox ){
                if ( section && checkbox ){
                    if (
                        section.querySelectorAll( "[type=\"checkbox\"]" ).length ===
                        section.querySelectorAll( "[type=\"checkbox\"]:checked" ).length
                    ){
                        checkbox.checked = true;
                        this.updatePrintAllCheckBox();
                    }
                }
                else {
                    const all = this.$refs.favoritesArea.querySelectorAll( "[type=\"checkbox\"]" ).length,
                          checked = this.$refs.favoritesArea.querySelectorAll( "[type=\"checkbox\"]:checked" ).length;
                    if ( all === checked )
                        this.$refs.print_all.checked = true;
                }
                this.$refs.printButton.disabled = false;
            },
            updatePrintButtonState(){
                const totalChecked = this.$refs.favoritesArea.querySelectorAll( "[type=\"checkbox\"]:checked" ).length;
                this.$refs.printButton.disabled = !totalChecked;
            }
        }
    };
</script>
