<template>
    <div>
        <PageHeader :className="slug" :heading="headerHeading">
            <div v-if="isArrayView" class="page-navigation">
                <router-link
                    class="previous"
                    v-if="totalPages > 1"
                    :to="{
                        name : slug === 'lifestyles' ? 'LifestylePage' : 'AboutKolterPage',
                        params: { id: getPreviousPageSlug() }
                    }" />
                <router-link :to="{ name : slug === 'lifestyles' ? 'Lifestyles' : 'AboutKolter' }" class="home" />
                <router-link
                    class="next"
                    v-if="totalPages > 1"
                    :to="{
                        name : slug === 'lifestyles' ? 'LifestylePage' : 'AboutKolterPage',
                        params: { id: getNextPageSlug() }
                    }" />
            </div>
        </PageHeader>

        <div class="page">
            <div class="slideshow" ref="slideshow">
                <div v-for="(image,index) in page.images" :key="image.location" class="slide" :class="{'active':(index === 0)}" @touchstart="touchStart" @touchend="touchEnd" @touchmove="touchMove" @mousedown="touchStart">
                    <img :src="image.location" />
                </div>
                <div class="bullets" v-if="page.images.length > 1">
                    <div v-for="(image,index) in page.images" :key="index" class="bullet" :data-index="index" @click="goTo(index)">
                        <i class="fa-circle" :class="{'far':(index !== 0),'fas':(index === 0)}"></i>
                    </div>
                </div>
            </div>
            <div class="content">
                <header v-html="pageHeader"></header>
                <div v-html="page.content"></div>
                <a v-if="isOnline && videoImage.length"
                   :href="videoEmbedUrl"
                   class="video"
                   target="_blank"
                   rel="noopener noreferrer"
                   data-type="html5video"
                   data-fancybox="video-gallery">
                    <i class="play-button"></i>
                    <img :src="videoImage" />
                </a>
            </div>
        </div>
    </div>
</template>

<script>
    import PageHeader from "@/components/PageHeader.vue";

    export default {
        components: {
            PageHeader
        },
        data() {
            return {
                bullets             : null,
                currentInterval     : 0,
                currentSlide        : 0,
                slides              : null,
                didEnter            : false,
                posX1               : 0,
                posX2               : 0,
                posInitial          : 0,
                posFinal            : 0,
                slideInMotion       : null,
                threshold           : 250
            };
        },
        props: {
            slug: {
                type: String,
                required: true,
                default: ""
            },
            id: {
                type: String
            }
        },
        computed: {
            isOnline(){
                return this.$store.state.isOnline;
            },
            headerHeading() {
                return this.slug === "about" ? "About Community" : this.slug;
            },
            isArrayView() {
                return this.id && Array.isArray( this.$store.state.kiosk[this.slug] );
            },
            pageIndex() {
                const data = this.$store.state.kiosk[this.slug];
                return this.isArrayView
                    ? data.findIndex( row => row.slug === this.id )
                    : 0;
            },
            totalPages(){
                return this.isArrayView
                    ? this.$store.state.kiosk[this.slug].length
                    : 1;
            },
            page() {
                let data = this.$store.state.kiosk[this.slug];
                // fetch page from array
                if ( this.isArrayView )
                    data = data.find( row => row.slug === this.id );
                // TODO: Reroute if nothing
                return data;
            },
            totalSlides() {
                return this.page.images.length;
            },
            videoImage() {
                return this.page.videoThumbnail || "";
            },
            videoEmbedUrl() {
                let videoEmbedUrl = this.page.videoID || "";
                if ( videoEmbedUrl !== "" )
                    videoEmbedUrl = `https://www.youtube.com/watch?v=${videoEmbedUrl}`;
                return videoEmbedUrl;
            },
            pageHeader() {
                return `<h1>${this.page.title}</h1>`;
            }
        },
        methods:{
            getNextPageSlug() {
                let nextIndex = this.pageIndex + 1;

                if ( nextIndex >= this.totalPages )
                    nextIndex = 0;

                return this.$store.state.kiosk[this.slug][nextIndex].slug;
            },
            getPreviousPageSlug() {
                let prevIndex = this.pageIndex - 1;

                if ( prevIndex < 0 )
                    prevIndex = this.totalPages - 1;

                return this.$store.state.kiosk[this.slug][prevIndex].slug;
            },
            goTo( index ) {
                this.stopSlideShow();
                this.animateSlideShow( index );
                this.startSlideShow();
            },
            animateSlideShow( goTo ) {
                // run if not current slide and or is not passed
                if ( typeof goTo !== "number" || this.currentSlide !== goTo ){

                    const   currentSlide    = this.slides[this.currentSlide],
                            currentBullet   = this.bullets[this.currentSlide];

                    currentSlide.classList.remove( "active" );
                    currentSlide.classList.add( "previous" );
                    currentBullet.querySelector( "[data-prefix]" ).setAttribute( "data-prefix", "far" );

                    // increase slide
                    if ( typeof goTo !== "undefined" )
                        this.currentSlide = goTo;
                    else
                        this.currentSlide++;

                    // make sure if we are above total or negative set to 0
                    if ( this.totalSlides <= this.currentSlide || this.currentSlide < 0 )
                        this.currentSlide = 0;

                    this.slides[this.currentSlide].classList.remove( "previous", "next", "slideLeft", "slideRight" );
                    this.slides[this.currentSlide].classList.add( "active" );
                    this.bullets[this.currentSlide].querySelector( "[data-prefix]" ).setAttribute( "data-prefix", "fas" );

                    this.slides[this.currentSlide].addEventListener( "transitionend", () => {
                        currentSlide.classList.remove( "previous", "slideLeft", "slideRight" );
                    }, {
                        once: true
                    } );
                }
            },
            startSlideShow() {
                if ( !this.slides ) {
                    this.slides  = this.$refs.slideshow.querySelectorAll( ".slide" );
                    this.bullets = this.$refs.slideshow.querySelectorAll( ".bullet" );
                }
                // start the slideshow
                if ( this.totalSlides > 1 ){
                    this.currentInterval = setInterval( () => {
                        this.animateSlideShow();
                    }, 8000 );
                }
            },
            stopSlideShow() {
                clearInterval( this.currentInterval );
            },
            resetData() {
                this.bullets            = null;
                this.currentInterval    = 0;
                this.currentSlide       = 0;
                this.slides             = null;
            },
            touchStart( event ){
                event = event || window.event;

                event.preventDefault();

                // stop slideshow on drag start and set initial position values
                // for tracking move
                this.stopSlideShow();
                this.slideInMotion = event.target.offsetParent;
                this.posInitial = this.slideInMotion.offsetLeft;
                // remove previous from all existing slides
                this.slides.forEach( slide => slide.classList.remove( "previous", "slideLeft", "slideRight" ) );
                this.posFinal = 0;
                this.slideInMotion.classList.add( "inmotion" );

                // set position and events based on type
                if ( event.type === "touchstart" ){
                    this.posX1 = event.touches[0].clientX;
                }
                else{
                    this.posX1 = event.clientX;
                    document.onmouseup      = this.touchEnd;
                    document.onmousemove    = this.touchMove;
                }
            },
            touchMove( event ){
                event = event || window.event;

                // set current x positions to track changes
                if ( event.type == "touchmove" ){
                    this.posX2 = this.posX1 - event.touches[0].clientX;
                    this.posX1 = event.touches[0].clientX;
                }
                else {
                    this.posX2 = this.posX1 - event.clientX;
                    this.posX1 = event.clientX;
                }
                // set direction and next and previous index for UI updates
                const   direction  = new DOMMatrix( window.getComputedStyle( this.slideInMotion ).transform ).m41 - this.posX2,
                        next       = this.currentSlide + 1 >= this.totalSlides ? 0 : this.currentSlide + 1,
                        previous   = this.currentSlide - 1 < 0 ? this.totalSlides - 1 : this.currentSlide - 1;
                // update next and previous slides for reveal
                if ( direction > 0 ){
                    this.slides[next].classList.remove( "next" );
                    this.slides[previous].classList.add( "next" );
                }
                else{
                    this.slides[previous].classList.remove( "next" );
                    this.slides[next].classList.add( "next" );
                }
                // move slide
                this.slideInMotion.style.transform = `translateX( ${direction}px )`;
            },
            touchEnd( event ){
                event = event || window.event;

                // set final position
                this.posFinal = new DOMMatrix( window.getComputedStyle( this.slideInMotion ).transform ).m41;
                this.slideInMotion.classList.remove( "inmotion" );
                // decide if there is motion based on threshold check and direction
                if ( this.posFinal - this.posInitial < -this.threshold ){
                    this.slideInMotion.classList.add( "slideLeft" );
                    this.animateSlideShow( this.currentSlide + 1 );
                }
                else if ( this.posFinal - this.posInitial > this.threshold ){
                    this.slideInMotion.classList.add( "slideRight" );
                    this.animateSlideShow( this.currentSlide - 1 >= 0 ? this.currentSlide - 1 : this.totalSlides - 1 );
                }
                // always reset position
                this.slideInMotion.style.transform = "";
                // restart slideshow timer
                this.startSlideShow();
                // reset document listeners
                if ( event.type === "mouseup" ){
                    document.onmouseup = null;
                    document.onmousemove = null;
                }
            }
        },
        beforeRouteEnter( to, from, next ) {
            next( vm => {
                vm.startSlideShow();
                vm.didEnter = true;
            } );
        },
        beforeRouteLeave( to,from ) {
            document.getElementById( "app" ).classList.remove( this.slug );
            this.stopSlideShow();
            this.resetData();
            return true;
        },
        beforeRouteUpdate( to,from ) {
            if ( to.name === from.name && to.path !== from.path ){
                this.stopSlideShow();
                this.resetData();
                // schedule startSlideShow after view update
                setTimeout( () => {
                    this.startSlideShow();
                }, 250 );
            }
        },
        mounted() {
            if ( !this.didEnter )
                this.startSlideShow();
            document.getElementById( "app" ).classList.add( this.slug );
        },
        unmounted(){
            // makes sure to remove slug if leaving path
            // as router handles setting
            document.getElementById( "app" ).classList.remove( this.slug );
        }
    };
</script>
