<template>
    <header>
        <div>
            <i class="fal fa-book-heart" v-if="className === 'brochure'"></i>
            <i :class="className" v-else></i>
            <h1 v-html="heading"></h1>
        </div>
        <slot></slot>
    </header>
</template>

<script>
    export default {
        name: "PageHeader",
        props : {
            className: {
                type: String,
                required: true
            },
            heading: {
                type: String,
                required: true
            }
        }
    };
</script>
