<template>
    <div>
        <PageHeader className="galleries" heading="Photos &amp; Videos" />
        <div class="tile-list gallery-list">
            <router-link v-for="tile in tiles"
                         :to="tile.slug"
                         :key="tile.slug"
                         :style="[ tile.tileImage.length ? {backgroundImage : `url(${tile.tileImage})`} : null ]"
                         class="tile" >
                <h2 v-html="tile.title"></h2>
            </router-link>
        </div>
    </div>
</template>

<script>
    import PageHeader from "@/components/PageHeader.vue";

    export default {
        components: {
            PageHeader
        },
        props: {
            slug: {
                type: String,
                required: true,
                default: ""
            }
        },
        computed: {
            tiles() { return this.$store.state.kiosk.galleries; }
        },
        beforeRouteLeave( to ) {
            if( to.name === "Gallery" )
                document.getElementById( "app" ).classList.add( this.slug );
        }
    };
</script>
