<template>
    <div>
        <div :class="{'with-sidebar': !interactiveAreaMap }" id="area-map-sidebar" v-show="isOnline">
            <Sidebar className="area-map" heading="Area Map" v-if="!interactiveAreaMap" @click.capture="saveFavorite($event)">
                <div id="area-map-favorites" ref="mapFavorites"></div>
                <div id="area-map-categories" ref="mapCategories"></div>
            </Sidebar>

            <div id="area-map" class="content-area" ref="map" v-if="!interactiveAreaMap"></div>

            <div id="interactive-area-map" class="content-area" ref="interactiveAreaMap" v-if="interactiveAreaMap && isOnline">
                <iframe :src="interactiveAreaMap"></iframe>
            </div>
        </div>
        <Offline v-if="isOffline" />
    </div>
</template>

<script>
    import Sidebar from "@/components/Sidebar.vue";
    import Offline from "@/components/Offline.vue";
    import { Loader } from "@googlemaps/js-api-loader";
    import MapViewer from "@/plugins/MapViewer.js";
    import Favorites from "@/plugins/Favorites.js";

    const loader = new Loader( {
        apiKey: "AIzaSyBj2FBv5FzjmHS_GOrhsv6ScP0j97NIGsM",
        version: "beta",
        libraries: ["places","geometry"]
    } );

    export default {
        components:{
            Sidebar,
            Offline
        },
        computed:{
            isOffline(){ return this.$store.state.isOffline; },
            isOnline(){ return this.$store.state.isOnline; },
            interactiveAreaMap() {
                return this.$store.state.kiosk.aareas && this.$store.state.kiosk.aareas.areamap ?
                    this.$store.state.kiosk.aareas.areamap :
                    null;
            }
        },
        methods:{
            saveFavorite( event ){
                const target = event.target.closest( "[data-favorite]" );

                if ( target ){
                    event.preventDefault();
                    event.stopImmediatePropagation();
                    Favorites.saveFavorite( event );
                    MapViewer.updateFavorite( event.target.closest( "li" ), true );
                }
            },
            receiveMessage( event ){
                const source_origins = [
                    "https://apps.zondavirtual.com",
                    "https://local.fusedev.com:8080",
                    "https://vsm.aareas.com",
                    "https://vsmalpha.aareas.com"
                ];
                let allowed_source = source_origins.find( _origin => event.origin === _origin );
                if ( allowed_source ){
                    // a route push
                    if ( event.data.path ){
                        let pathMatch = event.data.path.match( /floorplans|move-in-ready/ );
                        // we are navigating to floorplans or move-in-ready
                        // so we need to reset any search criteria to make sure
                        // there is no issues with navigation
                        if ( pathMatch ) {
                            // lets define which key
                            let filterKey = pathMatch[0] === "floorplans" ?
                                "floorplans" :
                                "inventory";
                            // set key if it does not exists
                            if ( !this.$store.state.searchParams[ filterKey ] )
                                this.$store.state.searchParams[ filterKey ] = {};
                            // set search criteria
                            this.$store.state.searchParams[ filterKey ].sqft       = parseInt( event.data.sqft || 0 );
                            this.$store.state.searchParams[ filterKey ].bedrooms   = parseInt( event.data.bedrooms || 0 );
                            this.$store.state.searchParams[ filterKey ].collection = parseInt( event.data.collection || 0 );
                        }
                        // navigate
                        this.$router.push( event.data.path );
                    }
                    // a favorite save
                    else if ( event.data.favorite ){
                        let favorite = {communityid: "0", modelid: "0", link: "", mapentryid: event.data.favorite, favorite: "1"};
                        this.$store.commit( "SAVE_FAVORITE", favorite );
                    }
                    // loaded event to send current favorites
                    else if ( event.data.loaded ){
                        let favorites = [];
                        this.$store.state.favorites.forEach( entry => {
                            if ( entry.mapentryid )
                                favorites.push( { "id" : entry.mapentryid } );
                        } );
                        if ( favorites.length )
                            event.source.postMessage( favorites, allowed_source );
                    }
                }
            }
        },
        async mounted(){
            if ( this.isOnline ){
                if ( this.interactiveAreaMap ){
                    // setup event listener for iframe events
                    window.addEventListener( "message", this.receiveMessage, false );
                }
                else {
                    // load google maps
                    await loader.load();
                    // load the viewer ( state is used in plugin to fetch data )
                    MapViewer.init(
                        this.$refs.map,
                        this.$refs.mapCategories,
                        this.$refs.mapFavorites,
                        document.querySelector( ".sidebar-content" )
                    );
                    Favorites.checkFavorites( this.$refs.mapCategories );
                    MapViewer.loadFavorites();
                }
            }
        },
        unmounted() {
            // remove iframe events listeners
            if ( this.interactiveAreaMap )
                window.removeEventListener( "message", this.receiveMessage, false );
            else
                MapViewer.destroy();
        }
    };
</script>
