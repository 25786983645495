<template>
    <div>
        <PageHeader :className="slug" :heading="headerHeading" />
        <div class="tile-list" :class="slug">
            <router-link v-for="tile in tiles"
                         :to="tile.slug"
                         :key="tile.slug"
                         :style="[ tile.tileImage.length ? {backgroundImage : `url(${tile.tileImage})`} : null ]"
                         class="tile" >
                <h2 v-html="tile.title"></h2>
            </router-link>
        </div>
    </div>
</template>

<script>
    import PageHeader from "@/components/PageHeader.vue";

    export default {
        components: {
            PageHeader
        },
        props: {
            slug: {
                type: String,
                required: true,
                default: ""
            }
        },
        computed: {
            headerHeading() {
                return this.slug === "kolter" ? "About Kolter" : this.slug;
            },
            tiles() {
                return this.$store.state.kiosk[this.slug];
            }
        },
        beforeRouteLeave( to ) {
            if( ["LifestylePage","AboutKolterPage"].indexOf( to.name ) !== -1 )
                document.getElementById( "app" ).classList.add( this.slug );
        }
    };
</script>
