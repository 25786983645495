import store from "../store";
import Favorites from "./Favorites";

export default {
    // globals required
    globals : {
        categories  : null,
        location    : null,
        places      : []
    },
    // google maps
    gm : {
        map : null,
        activeMarker : null,
        fromElement : false
    },
    // ui elements
    ui : {
        map: null,
        mapCategories: null,
        mapFavorites: null,
        mapFavoritesList: null,
        scrollArea: null,
        currentFavoriteButton : null,
        sidebar: null
    },
    // data stores
    data : {
        activeCategory: null,
        existingPlaces: [],
        content : null
    },
    // debugging
    debug : {
        enabled : location.search.indexOf( "debug" ) !== -1 ? true : false,
        style   : "font-family: 'Operator Mono SSm Lig', Menlo, Consolas, monospace; color:firebrick;",
        lStyle  : "color:orange; font-weight:bold;",
        log     : function(){}
    },

    /**
     * Pass in document element references
     */
    init( map, mapCategories, mapFavorites, scrollArea ){
        const $this = this;
        // setup debugging - bind console log so we can get proper line numbers (new way)
        $this.debug.log = $this.debug.enabled && window.console ? console.log.bind( window.console ) : function(){};
        // start
        $this.debug.log( "%cMapViewer.init()", $this.debug.style );
        // set map data, continue to load
        $this.globals           = JSON.parse( JSON.stringify( store.state.kiosk.map ) );
        $this.data.content      = store.state.kiosk.mapContent;
        // setup ui elements
        $this.ui.map            = map;
        $this.ui.mapFavorites   = mapFavorites;
        $this.ui.mapCategories  = mapCategories;
        $this.ui.scrollArea     = scrollArea;
        $this.ui.sidebar        = document.getElementById( "area-map-sidebar" );
        // setup listeners
        $this.setupUIAndEventListeners();
        // continue init ( adds first location to view )
        $this.setupMap();
        // set up favorites holder
        $this.ui.mapFavorites.innerHTML = $this.createCategoryHTML( "favorites","My Favorite POIs" );
        $this.ui.mapFavoritesList       = mapFavorites.querySelector( "ul" );
        // finalize
        $this.loadExistingPlaces();
    },

    destroy(){
        this.removeEventListeners();

        this.debug.log( "%cMapViewer.destroy()", this.debug.style );

        this.gm = {
            map : null,
            activeMarker : null,
            fromElement : false
        };
        this.ui = {
            map: null,
            mapCategories: null,
            mapFavorites: null,
            mapFavoritesList: null,
            scrollArea: null,
            currentFavoriteButton : null,
            sidebar: null
        };
        this.data = {
            activeCategory: null,
            existingPlaces: [],
            content : null
        };
    },

    setupMap(){
        this.debug.log( "%cMapViewer.setupMap()", this.debug.style );

        const $this = this,
            smallIcon = new google.maps.Size( 31,40 ),
            largeIcon = new google.maps.Size( 45,60 );

        // set view on
        document.body.classList.add( "on" );

        // set up the icons
        $this.globals.categories.map( category => {
            category.icon = {
                url         : category.icon.replace( ".svg",".png" ).replace( "svg/icon","img/ui" ),
                size        : smallIcon,
                scaledSize  : smallIcon
            };
        } );

        // setup the center
        $this.gm.center = new google.maps.LatLng( $this.globals.location.lat,$this.globals.location.lng );

        // create the map
        const map = new google.maps.Map( $this.ui.map, {
            center              : $this.gm.center,
            zoom                : 12,
            rotateControl       : false,
            zoomControl         : true,
            zoomControlOptions: {
                position: google.maps.ControlPosition.RIGHT_BOTTOM
            },
            scaleControl        : true,
            fullscreenControl   : false,
            streetViewControl   : false,
            mapTypeControl      : false,
            clickableIcons      : false,
            mapTypeId           : "roadmap"
        } );
        $this.gm.map = map;

        // full screen control
        if ( window.parent !== window ) {
            const fullScreenControl = document.createElement( "a" );
            fullScreenControl.href = location.href;
            fullScreenControl.target = "blank";
            fullScreenControl.id = "fullscreen";
            fullScreenControl.innerHTML = "<i class=\"fas fa-expand\"></i>";
            map.controls[google.maps.ControlPosition.LEFT_TOP].push( fullScreenControl );
        }

        // our initial marker w/ click listener
        const marker = new google.maps.Marker( {
            place_id            : "main",
            place_level         : 0,
            map                 : $this.gm.map,
            position            : $this.gm.center,
            animation           : google.maps.Animation.DROP,
            collisionBehavior   : google.maps.CollisionBehavior.REQUIRED_AND_HIDES_OPTIONAL,
            icon                : {
                url         : this.globals.location.marker.replace( ".svg",".png" ).replace( "svg/ui","img/ui" ),
                size        : largeIcon,
                scaledSize  : largeIcon
            }
        } );
        marker.addListener( "click",function(){
            $this.toggleActiveMarker( this );
        } );
        // set geometry for render
        this.globals.location.geometry = {
            location : $this.gm.center
        };
        // save reference to marker in place object
        this.globals.location.marker = marker;
        // save a place id as main for loopup
        this.globals.location.place_id = "main";
        // add location to view
        this.addLocationToView();
    },

    setupUIAndEventListeners(){
        this.debug.log( "%cMapViewer.setupUIAndEventListeners()", this.debug.style );
        // bind this to the listeners for proper remove event listeners
        this.locationClickListener = this.locationClickListener.bind( this );
        this.categoryChangeListener = this.categoryChangeListener.bind( this );
        // click on location
        this.ui.mapCategories.addEventListener( "click", this.locationClickListener, true );
        this.ui.mapFavorites.addEventListener( "click", this.locationClickListener, true );
        // on change of view state of category
        this.ui.mapCategories.addEventListener( "change", this.categoryChangeListener, true );
        this.ui.mapFavorites.addEventListener( "change", this.categoryChangeListener, true );
    },

    removeEventListeners(){
        this.debug.log( "%cMapViewer.removeEventListeners()", this.debug.style );
        this.ui.mapCategories.removeEventListener( "click", this.locationClickListener, true );
        this.ui.mapCategories.removeEventListener( "change", this.categoryChangeListener, true );
        this.ui.mapFavorites.removeEventListener( "click", this.locationClickListener, true );
        this.ui.mapFavorites.removeEventListener( "change", this.categoryChangeListener, true );
    },

    locationClickListener( event ){
        this.debug.log( "%cMapViewer.locationClickListener()", this.debug.style );

        const $this = this,
            target = event.target.closest( "li" );

        if ( target ){
            $this.debug.log( "%c\tmapCategories.click", $this.debug.style + $this.debug.lStyle, {target} );

            let isTarget;

            for ( let category of $this.data.existingPlaces ){
                for ( let place of category.places ){
                    if ( place.place_id === target.dataset.id ){
                        isTarget = place;
                        break;
                    }
                }
                if ( isTarget )
                    break;
            }
            if ( isTarget ){
                $this.gm.fromElement = true;
                google.maps.event.trigger( isTarget.marker, "click" );
            }
        }
    },

    categoryChangeListener( event ){
        this.debug.log( "%cMapViewer.categoryChangeListener()", this.debug.style );

        const $this = this,
            target = event.target.closest( "input" );

        if ( target ){
            const favorites = Array.from( $this.ui.mapFavoritesList.querySelectorAll( "li" ) ).map( entry => entry.dataset.id );

            $this.debug.log( "%c\tmapCategories.change", $this.debug.style + $this.debug.lStyle, {target} );

            if ( target.dataset.id  !== "favorites" ){
                const id = parseInt( target.dataset.id ),
                    category = this.data.existingPlaces.find( place => place.category.id === id );

                if ( category ){
                    // disable active link within category
                    if ( !target.checked ){
                        const active_li = category.container.querySelector( "li.active" );
                        if ( active_li )
                            active_li.classList.remove( "active" );
                    }
                    for ( let place of category.places ){
                        // take action if not a favorite
                        if ( favorites.indexOf( place.place_id ) === -1 ){
                            place.marker.setAnimation( null );
                            place.marker.setVisible( target.checked );
                        }
                    }
                }
            }
            else {
                // get all the favorites
                this.data.existingPlaces.forEach( category => {
                    category.places.forEach( place => {
                        // take action if a favorite
                        if ( favorites.indexOf( place.place_id ) !== -1 ){
                            place.marker.setAnimation( null );
                            place.marker.setVisible( target.checked );
                        }
                    } );
                } );
            }
        }
    },

    // ui
    loadExistingPlaces(){
        this.debug.log( "%cMapViewer.loadExistingPlaces()", this.debug.style );

        const   $this       = this,
            bounds      = new google.maps.LatLngBounds();

        this.globals.places.forEach( ( category,index ) => {
            const existingPlace = $this.addCategory( category.id );

            // set up places and add markers
            category.places.forEach( place => {
                // update geometry for render
                place.geometry = {
                    location : new google.maps.LatLng( place.geometry.lat,place.geometry.lng )
                };
                // create marker
                const marker = new google.maps.Marker( {
                    place_id            : place.place_id,
                    place_level         : index + 1,
                    map                 : $this.gm.map,
                    animation           : google.maps.Animation.DROP,
                    position            : place.geometry.location,
                    collisionBehavior   : google.maps.CollisionBehavior.REQUIRED_AND_HIDES_OPTIONAL,
                    icon                : $this.data.activeCategory.icon
                } );
                // save reference to marker in place object
                place.marker = marker;
                // add listener
                marker.addListener( "click",function(){
                    $this.toggleActiveMarker( this );
                } );
            } );

            existingPlace.places = category.places;

            // add places to categories
            $this.addPlaceToCategory( existingPlace, true );
        } );
    },

    addLocationToView(){
        this.debug.log( "%cMapViewer.addLocationToView()", this.debug.style );

        const container = document.createElement( "div" );

        container.id = "map_location";
        container.innerHTML = this.createCategoryHTML( 0,this.globals.location.name );

        this.ui.mapCategories.appendChild( container );

        if ( this.data.content && this.data.content !== "" ){
            const content_container = document.createElement( "div" );
            content_container.classList.add( "map_content" );
            content_container.innerHTML = this.data.content;
            this.ui.mapCategories.appendChild( content_container );
        }

        // add location text
        const locationText = `
            <li class="d-flex justify-content-between align-items-center border-top p-3 small" data-id="${this.globals.location.place_id}">
                <address class="m-0">
                    ${this.globals.location.address.address1}
                    ${this.globals.location.address.address2 && this.globals.location.address.address2 !== "" ? `, ${this.globals.location.address.address2}` : ""}
                    , ${this.globals.location.address.city}, ${this.globals.location.address.stateAbb} ${this.globals.location.address.zipCode}
                </address>
            </li>
        `;

        container.querySelector( "ul" ).innerHTML += locationText;

        // add location to existing places
        this.data.existingPlaces.push( {
            category    : {},
            places      : [this.globals.location],
            container   : container
        } );
    },

    createCategoryHTML( id,title ){
        this.debug.log( `%cMapViewer.createCategoryHTML(${id})`, this.debug.style );
        return `
            <div class="accordion ${ id === "favorites" ? "" : "mb-3"}" id="category_accordion_${id}">
                <div class="accordion-item">
                    <div class="accordion-header d-flex align-items-center ps-3" id="category_heading_${id}">
                        ${ id === 0 ? `<img src=\"${this.globals.location.icon}" width=\"24\" />` : `
                            <div class="form-check form-switch form-select-lg">
                                <input class="form-check-input" type="checkbox" id="category_switch_${id}" data-id="${id}" checked>
                            </div>
                        `}
                        <button class="accordion-button ps-1 ${id === 0 ? "" :"collapsed"}" type="button" data-bs-toggle="collapse" data-bs-target="#category_body_${id}" aria-expanded="false" aria-controls="category_body_${id}">
                            <span>${title}</span>
                            ${ id === "favorites" ? "<span><span class=\"counter\"></span></span>" : ""}
                        </button>
                    </div>
                    <div id="category_body_${id}" class="accordion-collapse collapse ${id === 0 ? "show" :""}" aria-labelledby="category_heading_${id}" data-bs-parent="#category_accordion_${id}">
                        <div class="accordion-body p-0">
                            <ul class="list-unstyled m-0">
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        `;
    },

    addCategory( id ){
        this.debug.log( `%cMapViewer.addCategory(${id})`, this.debug.style );

        const category = this.globals.categories.find( category => category.id === parseInt( id ) );

        if ( category ){
            const container = document.createElement( "div" );

            container.id = `category_${category.id}`;
            container.setAttribute( "data-id",category.id );
            container.className = `category ${category.className}`;
            container.innerHTML = this.createCategoryHTML( category.id,category.title );

            this.data.existingPlaces.push( {
                category    : category,
                places      : [],
                container   : container
            } );
            this.ui.mapCategories.appendChild( container );
            this.setActiveCategory( container );

            return this.data.existingPlaces[this.data.existingPlaces.length-1];
        }
    },

    setActiveCategory( target ){
        this.debug.log( "%cMapViewer.setActiveCategory()", this.debug.style );

        const currentActiveCategory = this.ui.mapCategories.querySelector( "div.category.active" );
        if ( currentActiveCategory )
            currentActiveCategory.classList.remove( "active" );
        target.classList.add( "active" );
        this.data.activeCategory = this.globals.categories.find( c => c.id === parseInt( target.dataset.id ) );
    },

    toggleActiveMarker( marker ){
        this.debug.log( "%cMapViewer.toggleActiveMarker()", this.debug.style );

        const   $this   = this,
            container   = $this.data.existingPlaces[marker.place_level].container,
            button      = container.querySelector( ".accordion-button" ),
            li          = container.querySelector( `[data-id="${marker.place_id}"]` ),
            active_li   = $this.ui.mapCategories.querySelector( "li.active" ),
            checkbox    = container.querySelector( ".form-check-input" ),
            favCheckbox = $this.ui.mapFavorites.querySelector( ".form-check-input" ),
            favorites   = Array.from( $this.ui.mapFavoritesList.querySelectorAll( "li" ) ).map( entry => entry.dataset.id );

        // make sure favorites are enabled
        if ( favorites.indexOf( marker.place_id ) !== -1 && favCheckbox && !favCheckbox.checked )
            favCheckbox.click();

        // make sure category view is enabled and expanded
        if ( checkbox && !checkbox.checked )
            checkbox.click();

        // turn of existing active marker
        if ( $this.gm.activeMarker ){
            $this.gm.activeMarker.setAnimation( null );
            if ( active_li )
                active_li.classList.remove( "active" );
            // remove favorite selected
            const existingFavoriteLI = $this.ui.mapFavoritesList.querySelector( `li[data-id="${$this.gm.activeMarker.place_id}"]` );
            if ( existingFavoriteLI )
                existingFavoriteLI.classList.remove( "active" );
        }

        // activate marker if not already active
        if ( $this.gm.activeMarker !== marker && !marker.getAnimation() ) {
            // if view is collapsed make sure to open
            if ( button.classList.contains( "collapsed" ) )
                button.click();
            // set list items as active
            li.classList.add( "active" );
            const favoriteLI = $this.ui.mapFavoritesList.querySelector( `li[data-id="${li.dataset.id}"]` );
            if ( favoriteLI )
                favoriteLI.classList.add( "active" );
            // add favorite selected
            // set scenter of map to this marker
            if ( $this.gm.fromElement )
                $this.gm.map.setCenter( marker.position );
            // animate marker and set visible
            marker.setVisible( true );
            marker.setAnimation( google.maps.Animation.BOUNCE );

            const top_target = marker.place_level === 0 ? 0 :
                marker.place_level + 1 === $this.data.existingPlaces.length ? li.offsetTop :
                    li.offsetTop - 110;

            // make sure sidebar is open
            if ( $this.ui.sidebar && $this.ui.sidebar.classList.contains( "collapsed" ) )
                $this.ui.sidebar.classList.remove( "collapsed" );

            // scroll to element w/ timeout to allow for collapsed view to show
            setTimeout( () => {
                $this.ui.scrollArea.scrollTo( {
                    top         : top_target,
                    behavior    : "smooth"
                } );
            }, 250 );

            // set me as the current marker
            $this.gm.activeMarker = marker;
        }
        else {
            $this.gm.activeMarker = null;
        }

        $this.gm.fromElement = false;
    },

    addPlaceToCategory( existingPlace, addAll ) {
        this.debug.log( "%cMapViewer.addPlaceToCategory()", this.debug.style, {existingPlace,addAll} );

        const   places = addAll ? existingPlace.places : [existingPlace.places[existingPlace.places.length - 1]];
        let     str = "";
        places.forEach( place => {
            place.added = true;
            place.category_id = this.data.activeCategory.id;
            str += `
                <li class="d-flex justify-content-between align-items-center border-top p-3" data-id="${place.place_id}">
                    <div class="d-flex align-items-center w-100">
                        <img src="${this.data.activeCategory.icon.url}" height="24" />
                        <div class="small place_info">
                            <h3 class="m-0 h6">${place.name}</h3>
                            <address class="m-0 mt-1">
                                ${place.address.address1}
                                ${place.address.address2 && place.address.address2 !== "" ? `, ${place.address.address2}` : ""}
                                , ${place.address.city}, ${place.address.stateAbb} ${place.address.zipCode}
                            </address>
                            ${place.geometry ? `<div>${this.distanceInMiles( place.geometry.location )}</div>` : ""}
                        </div>
                        <a href="##"
                            data-communityID="0"
                            data-modelID="0"
                            data-link=""
                            data-mapEntryID="${place.id}"
                            data-favorite="1">
                            <i class="fal fa-heart"></i>
                        </a>
                    </div>
                </li>
            `;
        } );
        existingPlace.container.querySelector( "ul" ).innerHTML += str;
    },

    distanceInMiles( location ){
        this.debug.log( "%cMapViewer.distanceInMiles()", this.debug.style, {location} );

        const miles = ( google.maps.geometry.spherical.computeDistanceBetween( location,this.gm.center ) * 0.000621371 ).toFixed( 2 );
        return miles + ( miles === 1 ? " mile" : " miles" ) + " from <strong>" + this.globals.location.name + "</strong>" ;
    },

    loadFavorites(){
        this.debug.log( "%cMapViewer.loadFavorites()", this.debug.style );

        const favorites = this.ui.mapCategories.querySelectorAll( "li.favorite" );

        this.updateFavoritesCount( favorites.length );

        favorites.forEach( favorite => {
            this.updateFavorite( favorite,false );
        } );
    },

    updateFavoritesCount( count ){
        this.debug.log( "%cMapViewer.updateFavoritesCount()", this.debug.style );
        this.ui.mapFavorites.querySelector( ".counter" ).innerText = count;
    },

    updateFavorite( element, updateCount ){
        if ( element.classList.contains( "favorite" ) ){
            const elementClone = element.cloneNode( true );
            this.ui.mapFavoritesList.appendChild( elementClone );
            Favorites.setButtonState( elementClone, true );
        }
        else {
            const existingLI = this.ui.mapFavoritesList.querySelector( `li[data-id="${element.dataset.id}"]` );
            if ( existingLI )
                existingLI.remove();
            Favorites.setButtonState( this.ui.mapCategories.querySelector( `li[data-id="${element.dataset.id}"]` ), false );
        }

        if ( updateCount )
            this.updateFavoritesCount( this.ui.mapFavoritesList.querySelectorAll( "li" ).length );
    },

    debounce( fn, time ){
        let timeout;
        return function() {
            const args = arguments;
            const functionCall = () => fn.apply( this, args );
            clearTimeout( timeout );
            timeout = setTimeout( functionCall, time );
        };
    }
};
