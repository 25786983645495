<template>
    <header id="top-header" v-if="kiosk.id">
        <div class="offline-banner" v-if="isOffline">
            <i class="fad fa-exclamation-triangle"></i>
            Currently Offline - Limited Experience
        </div>
        <router-link to="/">Home</router-link>
        &bull;
        <router-link to="/area-map/">Area Map</router-link>
        &bull;
        <router-link to="/galleries/">Photos &amp; Videos</router-link>
        &bull;
        <router-link to="/floorplans/" v-if="floorplans">Floorplans</router-link>
        &bull;
        <router-link to="/move-in-ready/" class="inventory" v-if="inventory">Move In Ready</router-link>
        &bull;
        <router-link to="/lifestyles/" class="lifestyles">Lifestyles</router-link>
        &bull;
        <router-link to="/about-kolter/" class="kolter">About Kolter</router-link>
        &bull;
        <router-link to="/site-plan/" class="site-plan">Site Plan</router-link>
    </header>
</template>

<script>
    export default {
        props : ["kiosk"],
        computed:{
            floorplans() {
                return this.$store.state.kiosk.floorplans.records.length ? true : false;
            },
            inventory() {
                return this.$store.state.kiosk.inventory.records.length ? true : false;
            },
            isOffline(){
                return this.$store.state.isOffline;
            }
        }
    };
</script>
