<template>
    <div :class="{'with-sidebar': zoomify && isOnline }">
        <Sidebar className="site-plan" heading="Site Plan" v-if="zoomify" v-show="isOnline">
            <div v-if="zoomify">
                <select v-if="zoomify.siteplans.length > 1" @change="toggleZoomifyMap($event)">
                    <option v-for="(siteplan,index) in zoomify.siteplans"
                            :value="index"
                            :key="index">
                        {{ siteplan.label }}
                    </option>
                </select>

                <div v-for="(siteplan,index) in zoomify.siteplans" :key="index" ref="legends">
                    <div class="zoomify-legend" :class="{ active: index === currentIndex }">
                        <div v-for="legend in siteplan.legend"
                             :key="legend.label"
                             :style="{color:legend.text,backgroundColor:legend.color}"
                             :data-zoom="legend.coords"
                             @click="setZoomifyView($event)">
                            {{ legend.label }}
                        </div>
                    </div>
                </div>
            </div>

            <div class="site-plan-legend">
                <span><strong>LEGEND</strong></span>
                <span><i class="fas fa-circle sold"></i> Sold</span>
                <span v-if="displayContingencySale"><i class="fas fa-circle contingency-sale"></i> Contingency Sale</span>
                <span><i class="fas fa-circle iha"></i> Inventory Homes Available</span>
                <span><i class="fas fa-circle ah"></i> Available Homesites</span>
                <span><i class="fas fa-circle dh"></i> Developer Hold</span>
                <span><i class="fas fa-star mc"></i> Model</span>
            </div>
        </Sidebar>

        <div id="site-plan" class="content-area" ref="communityMap" v-if="zoomify" v-show="isOnline">
            <div id="site-plan-render"></div>
            <div id="site-plan-controls">
                <button type="button" data-zoom="in" ref="zoomIn"><i class="fal fa-plus"></i></button>
                <div></div>
                <button type="button" @click="zoom('reset')"><i class="fal fa-sync"></i></button>
                <div></div>
                <button type="button" data-zoom="out" ref="zoomOut"><i class="fal fa-minus"></i></button>
            </div>
        </div>

        <div id="interactive-site-plan" class="content-area" ref="interactiveSitePlan" v-if="interactiveSiteplan" v-show="isOnline">
            <iframe :src="interactiveSiteplan"></iframe>
        </div>

        <div id="static-site-plans" class="content-area" v-if="( (!zoomify && !interactiveSiteplan) || isOffline ) && siteplans.length" ref="staticSitePlans">
            <header>
                <div>
                    <i class="site-plan"></i>
                    <h1 v-if="siteplans.length === 1">{{ siteplans[0].title || 'Site Plan' }}</h1>
                    <select v-if="siteplans.length > 1" @change="toggleMap($event)">
                        <option v-for="(siteplan,index) in siteplans"
                                :value="index"
                                :key="index">
                            {{ siteplan.title || `Site Plan ${index + 1}` }}
                        </option>
                    </select>
                </div>
            </header>
            <section>
                <div v-for="(siteplan,index) in siteplans" :key="index" :class="{ 'on':index === currentStaticIndex }">
                    <img :src="siteplan.location" />
                </div>
            </section>
            <div class="site-plan-legend">
                <span><strong>LEGEND</strong></span>
                <span><i class="fas fa-circle sold"></i> Sold</span>
                <span v-if="displayContingencySale"><i class="fas fa-circle contingency-sale"></i> Contingency Sale</span>
                <span><i class="fas fa-circle iha"></i> Inventory Homes Available</span>
                <span><i class="fas fa-circle dh"></i> Available Homesites</span>
                <span><i class="fas fa-circle ah"></i> Developer Hold</span>
                <span><i class="fas fa-star mc"></i> Model</span>
            </div>
        </div>

        <Offline v-if="isOffline && !siteplans.length" />
    </div>
</template>

<script>
    import Sidebar from "@/components/Sidebar.vue";
    import Offline from "@/components/Offline.vue";

    export default {
        components:{
            Sidebar,
            Offline
        },
        data() {
            return {
                currentIndex : 0,
                currentStaticIndex: 0
            };
        },
        computed:{
            siteplans() { return this.$store.state.kiosk.siteplans; },
            zoomify() { return this.$store.state.kiosk.zoomify; },
            interactiveSiteplan() {
                return this.$store.state.kiosk.aareas && this.$store.state.kiosk.aareas.siteplan ?
                    this.$store.state.kiosk.aareas.siteplan :
                    null;
            },
            zPath() { return this.zoomify.siteplans[0].path; },
            isOffline(){ return this.$store.state.isOffline; },
            isOnline(){ return this.$store.state.isOnline; },
            displayContingencySale(){ return this.$store.state.kiosk.displayContingencySale; }
        },
        methods: {
            loadZoomify() {
                // add cache buster
                let date = new Date();
                // only use today's date if it is past 4am
                if ( date.getHours() < 4 )
                    date.setDate( date.getDate() - 1 );
                // now prepare the cached value
                date = new Intl.DateTimeFormat( "en-US", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit"
                } ).format( date ).split( "/" ).reverse().join( "" );
                Z.showImage( "site-plan-render", this.zPath + "/" + date, this.zoomify.options );
                // fixes view issues on page mounts
                setTimeout( () => {
                    Z.Viewer.autoResizeViewer();
                    this.$refs.communityMap.classList.add( "on" );
                }, 500 );

            },
            toggleZoomifyMap( event ) {
                this.currentIndex = parseInt( event.target.value );
                const siteplan = this.zoomify.siteplans[this.currentIndex];
                Z.Viewer.setImage( siteplan.path,siteplan.options );
            },
            setZoomifyView( event ) {
                const coords = event.target.dataset.zoom.split( "," ).map( function( a ){
                    return parseFloat( a );
                } );
                Z.Viewport.zoomAndPanToView( coords[0],coords[1],coords[2]/100 );
            },
            zoomStart( event ) {
                event.preventDefault();

                const path = event.path || event.composedPath();
                let target;

                for ( let step of path ){
                    if ( step.tagName === "BUTTON" && step.dataset.zoom ){
                        target = step;
                        break;
                    }
                }

                if ( target )
                    this.zoom( target.dataset.zoom );
            },
            zoomEnd( event ) {
                event.preventDefault();
                this.zoom( "stop" );
            },
            zoom( type ) {
                switch ( type ){
                    case "in":
                    case "out":
                    case "stop":
                        Z.Viewport.zoom( type );
                        break;
                    default:
                        Z.Viewport.reset();
                        break;
                }
            },
            transitionStart( event ) {
                if ( event.propertyName !== "width" ) return;
                this.$refs.communityMap.classList.add( "in-transition" );
            },
            transitionEnd( event ) {
                if ( event.propertyName !== "width" ) return;
                this.$refs.communityMap.classList.remove( "in-transition" );
                Z.Viewer.autoResizeViewer();
            },
            toggleMap( event ){
                this.currentStaticIndex = parseInt( event.target.value );
            },
            receiveMessage( event ){
                const source_origins = [
                    "https://apps.zondavirtual.com",
                    "https://local.fusedev.com:8080",
                    "https://vsm.aareas.com",
                    "https://vsmalpha.aareas.com"
                ];
                let allowed_source = source_origins.find( _origin => event.origin === _origin );
                if ( allowed_source ){
                    // a route push
                    if ( event.data.path ){
                        let pathMatch = event.data.path.match( /floorplans|move-in-ready/ );
                        // we are navigating to floorplans or move-in-ready
                        // so we need to reset any search criteria to make sure
                        // there is no issues with navigation
                        if ( pathMatch ) {
                            // lets define which key
                            let filterKey = pathMatch[0] === "floorplans" ?
                                "floorplans" :
                                "inventory";
                            // set key if it does not exists
                            if ( !this.$store.state.searchParams[ filterKey ] )
                                this.$store.state.searchParams[ filterKey ] = {};
                            // set search criteria
                            this.$store.state.searchParams[ filterKey ].sqft       = parseInt( event.data.sqft || 0 );
                            this.$store.state.searchParams[ filterKey ].bedrooms   = parseInt( event.data.bedrooms || 0 );
                            this.$store.state.searchParams[ filterKey ].collection = parseInt( event.data.collection || 0 );
                        }
                        // navigate
                        this.$router.push( event.data.path );
                    }
                    // a favorite save
                    else if ( event.data.favorite ){
                        let favorite = {communityid: "0", modelid: "0", link: "", mapentryid: event.data.favorite, favorite: "1"};
                        this.$store.commit( "SAVE_FAVORITE", favorite );
                    }
                    // loaded event to send current favorites
                    else if ( event.data.loaded ){
                        let favorites = [];
                        this.$store.state.favorites.forEach( entry => {
                            if ( entry.mapentryid )
                                favorites.push( { "id" : entry.mapentryid } );
                        } );
                        if ( favorites.length )
                            event.source.postMessage( favorites, allowed_source );
                    }
                }
            }
        },
        mounted() {
            if ( this.isOnline ){
                if ( this.zoomify ){
                    if ( typeof Z === "undefined" ){
                        const zScript = document.createElement( "script" );
                        zScript.src = this.zoomify.library;
                        zScript.onload = () => {
                            this.loadZoomify();
                        };
                        document.head.appendChild( zScript );
                    }
                    else {
                        this.loadZoomify();
                    }
                    // add event listeners
                    this.$refs.communityMap.addEventListener( "transitionstart",this.transitionStart );
                    this.$refs.communityMap.addEventListener( "transitionend",this.transitionEnd );
                    this.$refs.zoomIn.addEventListener( "touchstart", this.zoomStart );
                    this.$refs.zoomIn.addEventListener( "mousedown", this.zoomStart );
                    this.$refs.zoomIn.addEventListener( "touchend", this.zoomEnd );
                    this.$refs.zoomIn.addEventListener( "touchcancel", this.zoomEnd );
                    this.$refs.zoomOut.addEventListener( "mouseup", this.zoomEnd );
                    this.$refs.zoomOut.addEventListener( "touchstart", this.zoomStart );
                    this.$refs.zoomOut.addEventListener( "mousedown", this.zoomStart );
                    this.$refs.zoomOut.addEventListener( "touchend", this.zoomEnd );
                    this.$refs.zoomOut.addEventListener( "touchcancel", this.zoomEnd );
                    this.$refs.zoomOut.addEventListener( "mouseup", this.zoomEnd );
                }
                else if ( this.interactiveSiteplan ){
                    // setup event listener for iframe events
                    window.addEventListener( "message", this.receiveMessage, false );
                }
            }
        },
        unmounted() {
            // remove zoomify resize event listener
            if ( typeof Z !== "undefined" ){
                Z.Utils.removeEventListener( window, "resize", Z.Viewer.viewerEventsHandler );
                Z.Viewer.initializeViewerKeyDefaultListeners( false );
            }
            // remove iframe events listeners
            if ( this.interactiveSiteplan )
                window.removeEventListener( "message", this.receiveMessage, false );
        }
    };
</script>
