<template>
    <div class="modal-mask" @click="closeModal()">
        <div class="modal-wrapper">
            <div class="modal-container">

                <div class="modal-header" v-if="isAccountAction">
                    <h5 class="modal-title" v-html="headerTitle"></h5>
                    <button type="button" class="btn-close" aria-label="Close" @click="closeModal(true)"></button>
                </div>

                <div class="modal-body">
                    <div v-if="action === 'offline'">
                        <h5>
                            <strong>Sorry but it appears you are currently offline</strong>
                            <small class="d-block mt-2">This functionality requires an internet connection.</small>
                        </h5>
                    </div>
                    <div v-else-if="action === 'favorites'">
                        Try saving a few favorites to access this page.
                    </div>
                    <div v-else-if="action === 'clear-favorites'">
                        <h5>Are you sure you want to remove all your favorites?</h5>
                        <div class="d-flex justify-content-center mt-5">
                            <button class="btn btn-lg btn-secondary" @click="closeModal(true)">
                                <i class="fas fa-arrow-left"></i> No Go Back
                            </button>
                            <button class="btn btn-lg btn-danger ms-3" @click.stop="clearFavorites()">
                                <i class="fas fa-check"></i> Yes Continue
                            </button>
                        </div>
                    </div>
                    <form v-else-if="isAccountAction" @submit.prevent="submitForm" autocomplete="off" novalidate>
                        <!-- Login / Registration -->
                        <div v-if="!isLoggedIn">
                            <!-- Step 1 | Login / Registration -->
                            <div v-if="!inRegistration">
                                <p class="text-center">
                                    <strong>Enter your email to get started.</strong>
                                </p>
                                <div class="mb-3">
                                    <input type="email" class="form-control rounded-0" ref="emailField" placeholder="Email" v-model="form.email" />
                                </div>
                                <button type="button" class="btn custom-btn btn-lg w-100 rounded-0" disabled v-show="formProcessing">
                                    Processing <i class="fal fa-spinner fa-spin "></i>
                                </button>
                                <button type="submit" class="btn custom-btn btn-lg w-100 rounded-0" :disabled="!isValidEmail" v-show="!formProcessing">
                                    Continue <i class="fal fa-arrow-right"></i>
                                </button>
                            </div>
                            <!-- Step 2 | Registration -->
                            <div v-else-if="inRegistration">
                                <p class="text-center">
                                    <strong>
                                        Welcome!<br />
                                        Please complete your registration to continue.
                                    </strong>
                                </p>
                                <div class="mb-3 row">
                                    <div class="col-6">
                                        <input type="text" class="form-control rounded-0" placeholder="First Name" v-model="form.firstName" />
                                    </div>
                                    <div class="col-6">
                                        <input type="text" class="form-control rounded-0" placeholder="Last Name" v-model="form.lastName" />
                                    </div>
                                </div>
                                <button type="button" class="btn custom-btn btn-lg w-100 rounded-0" disabled v-show="formProcessing">
                                    Processing <i class="fal fa-spinner fa-spin "></i>
                                </button>
                                <button type="submit" class="btn custom-btn btn-lg w-100 rounded-0" :disabled="!isValidRegistration" v-show="!formProcessing">
                                    Register <i class="fal fa-arrow-right"></i>
                                </button>
                            </div>
                        </div>
                        <!-- Share -->
                        <div v-else-if="isLoggedIn">
                            <div class="mb-3">
                                <label for="shareToEmail" class="form-label">Send To</label>
                                <input type="text" class="form-control" id="shareToEmail" placeholder="Enter email addresses" v-model="form.shareToEmails" maxlength="500">
                            </div>
                            <div class="mb-3">
                                <label for="shareToMessage" class="form-label">Your Message ( Optional )</label>
                                <textarea class="form-control" id="shareToMessage" rows="3" v-model="form.shareToMessage" maxlength="1000"></textarea>
                            </div>
                            <button type="button" class="btn custom-btn btn-lg w-100 rounded-0" disabled v-show="formProcessing">
                                Processing <i class="fal fa-spinner fa-spin "></i>
                            </button>
                            <button type="submit" class="btn custom-btn btn-lg w-100 rounded-0" :disabled="!isValidShare" v-show="!formProcessing">
                                Send <i class="fad fa-paper-plane"></i>
                            </button>
                        </div>
                    </form>
                    <div v-else-if="action === 'logged-in'">
                        Welcome Back {{ customer.firstName }}!
                    </div>
                    <div v-else-if="action === 'registered'">
                        Welcome {{ customer.firstName }}!
                    </div>
                    <div v-else-if="action === 'log-out'">
                        You have succesfully logged out.
                    </div>
                    <div v-else-if="action === 'shared'">
                        Your favorites brochure has been shared!
                    </div>
                </div>

                <div class="modal-footer">
                    <slot name="footer"></slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Favorites from "@/plugins/Favorites.js";
    import Validate from "@/plugins/Validate.js";
    import KioskService from "@/services/KioskService.js";

    const autoCloseTimeout = 3000;

    export default {
        data(){
            return {
                inRegistration: false,
                formProcessing: false,
                fromShare: false,
                form: {
                    email : "",
                    firstName : "",
                    lastName : "",
                    phone: "",
                    shareToEmails : "",
                    shareToMessage : ""
                }
            };
        },
        computed: {
            action() { return this.$store.state.modal.action || ""; },
            customer() {
                return this.$store.state.customer;
            },
            isLoggedIn() {
                return this.customer.id ? true : false;
            },
            headerTitle() {
                return this.isLoggedIn ?
                    "Share this with someone.<small>Separate multiple email addresses with a comma.</small>" :
                    "Getting Started";
            },
            isAccountAction() {
                return this.action === "account-state" || this.action === "share-favorites";
            },
            isValidEmail() {
                return Validate.email( this.form.email );
            },
            isValidRegistration(){
                return this.form.firstName.trim() !== "" && this.form.lastName.trim() !== "";
            },
            isValidShare() {
                let isValid = true;
                this.form.shareToEmails.split( "," ).forEach( email => {
                    if ( isValid && !Validate.email( email.trim() ) )
                        isValid = false;
                } );
                return isValid;
            }
        },
        methods: {
            autoCloseModal(){
                if ( this.fromShare ){
                    this.fromShare = false;
                    setTimeout( () => {
                        this.$store.dispatch( "openModal", { action:"share-favorites", isModal: true } );
                    }, autoCloseTimeout - 1000 );
                }
                else {
                    setTimeout( () => {
                        this.closeModal( true );
                    }, autoCloseTimeout );
                }
            },
            clearFavorites(){
                Favorites.clearFavorites();
                this.closeModal( true );
            },
            closeModal( force ){
                if ( !this.$store.state.modal.isModal || force )
                    this.$store.dispatch( "closeModal" );
            },
            async doLogin(){
                try{
                    this.formProcessing = true;

                    const apiCall = await KioskService.login(
                        this.form.email,
                        this.$store.state.favorites
                    );

                    if ( apiCall.data.success ){
                        if ( apiCall.data.customer ){
                            this.$store.dispatch( "customerLogin", apiCall.data );
                            this.autoCloseModal();
                        }
                        else {
                            this.inRegistration = true;
                        }
                    }
                    else {
                        // TODO: Add error reporting
                        console.error( apiCall.data );
                    }
                }
                catch( error ){
                    // TODO: Add error reporting
                    console.error( error );
                }
                finally {
                    this.formProcessing = false;
                }
            },
            async doRegistration(){
                try{
                    this.formProcessing = true;

                    const apiCall = await KioskService.register(
                        this.form.email,
                        this.form.firstName,
                        this.form.lastName ,
                        this.$store.state.favorites
                    );

                    if ( apiCall.data.success ){
                        this.$store.dispatch( "customerRegistered", apiCall.data );
                        this.autoCloseModal();
                    }
                    else {
                        // TODO: Add error reporting
                        console.error( apiCall.data );
                    }
                }
                catch( error ){
                    // TODO: Add error reporting
                    console.error( error );
                }
                finally {
                    this.formProcessing = false;
                }
            },
            async doShare(){
                try{
                    this.formProcessing = true;

                    const apiCall = await KioskService.shareFavorites(
                        this.customer.id,
                        this.form.shareToEmails,
                        this.form.shareToMessage
                    );

                    if ( apiCall.data.success ){
                        // successful submission
                        this.$store.dispatch( "sharedSuccess" );
                        this.fromShare = false;
                        this.autoCloseModal();
                    }
                    else {
                        // TODO: Add error reporting
                        console.error( apiCall.data );
                    }
                }
                catch( error ){
                    // TODO: Add error reporting
                    console.error( error );
                }
                finally {
                    this.formProcessing = false;
                }
            },
            async submitForm(){
                if ( !this.isLoggedIn ){
                    if ( !this.inRegistration )
                        this.doLogin();
                    else
                        this.doRegistration();
                }
                else {
                    this.doShare();
                }
            }
        },
        mounted() {
            this.fromShare = this.action === "share-favorites";

            if ( this.action === "log-out" ){
                KioskService.logout(
                    this.$store.state.customer.loginID
                );
                this.$store.dispatch( "customerLogout" );
                this.autoCloseModal();
            }
        }
    };
</script>
