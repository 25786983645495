<template>
    <div class="sidebar" ref="sidebar">
        <a href="#" @click="toggleSidebar()" class="sidebar-toggle" v-if="collapsible === true"><i></i></a>
        <PageHeader :className="className" :heading="heading" />
        <div class="sidebar-content">
            <slot></slot>
        </div>
    </div>
</template>

<script>
    import PageHeader from "@/components/PageHeader.vue";

    export default {
        name: "Sidebar",
        components: {
            PageHeader
        },
        props : {
            className: {
                type     : String,
                required : true
            },
            heading: {
                type     : String,
                required : true
            },
            collapsible: {
                type    : Boolean,
                default : true
            }
        },
        methods: {
            toggleSidebar() {
                this.$refs.sidebar.parentElement.classList.toggle( "collapsed" );
            }
        }
    };
</script>
