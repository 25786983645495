import axios from "axios";

const apiClient = axios.create( {
    baseURL: process.env.VUE_APP_API_URL,
    method: "post",
    timeout: 15000,
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: process.env.VUE_APP_API_KEY
    }
} );
const communitySlug = location.hostname.split( "." ).at( 0 );

export default {
    getData() {
        return apiClient( {
            data: {
                communitySlug,
                type: "getData"
            }
        } );
    },
    login( email, favorites ) {
        return apiClient( {
            data: {
                action: "login",
                communitySlug,
                email,
                favorites
            }
        } );
    },
    register( email, firstName, lastName, favorites ) {
        return apiClient( {
            data: {
                action: "register",
                communitySlug,
                email,
                firstName,
                lastName,
                favorites
            }
        } );
    },
    logout( loginID ) {
        return apiClient( {
            data: {
                action: "logout",
                communitySlug,
                loginID
            }
        } );
    },
    saveFavorite( customerID,favorite ) {
        return apiClient( {
            data: {
                action: "favorite",
                communitySlug,
                customerID,
                favorite
            }
        } );
    },
    shareFavorites( customerID, shareToEmails,shareToMessage ) {
        return apiClient( {
            data: {
                action: "share",
                customerID,
                communitySlug,
                shareToEmails,
                shareToMessage
            }
        } );
    },
    getBrochure( brochure ) {
        return apiClient( {
            data: {
                action: "brochure",
                brochure,
                communitySlug
            }
        } );
    },
    ping() {
        return apiClient( {
            data: {
                action: "ping",
                communitySlug
            }
        } );
    }
};
