<template>
    <div class="offline">
        <div>
            <h1 v-if="!invalidKiosk">
                Sorry but it appears that you are offline.
                <small>
                    This view is only accessible with a succesful internet connection.<br />
                </small>
            </h1>
            <h1 v-else>
                Whoops!
                <small>
                    It appears you have used an invalid Kiosk URL.<br />
                    You can browse our communities by going to
                    <a href="https://www.kolterhomes.com/">www.kolterhomes.com</a>
                </small>
            </h1>
            <button class="m-auto custom-btn" @click="reload()" v-if="!invalidKiosk">Reload View</button>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            invalidKiosk: {
                type: Boolean,
                required: false,
                default: false
            }
        },
        methods: {
            reload(){
                location.reload();
            }
        }
    };
</script>
