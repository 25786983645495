<template>
    <div>
        <PageHeader :className="slug" :heading="headerHeading" />

        <div class="filter" @change="filterChange( true )">
            <label for="collection" v-if="collections.length > 1">Collection</label>
            <select id="collection" v-if="collections.length > 1" ref="collection" data-target="collection">
                <option value="0">ALL</option>
                <option v-for="option in collections" :key="option.id" :value="option.id">
                    {{ option.name }}
                </option>
            </select>
            <label for="bedrooms">Min Bed</label>
            <select id="bedrooms" ref="bedrooms" data-target="bedrooms">
                <option v-for="option in bedroom_options" :key="option.value" :value="option.value">
                    {{ option.label }}
                </option>
            </select>
            <label for="sqft">Min A/C Sq Ft</label>
            <select id="sqft" ref="sqft" data-target="sqft">
                <option v-for="option in sqft_options" :key="option.value" :value="option.value">
                    {{ option.label }}
                </option>
            </select>
            <button type="button" :disabled="!inSearch" @click="resetView( true )">
                <img src="/svg/reset.svg" /> &nbsp;RESET
            </button>
            <div class="totals">
                {{ totals }}
            </div>
        </div>

        <div class="floorplan-tiles" ref="tiles" @click.capture="saveFavorite($event)">
            <router-link v-for="model in models"
                         class="tile"
                         :to="model.data.slug"
                         :key="model.id"
                         :data-collection="model.data.collectionID"
                         :data-bedrooms="model.data.bedrooms"
                         :data-maxbedrooms="model.data.maxbedrooms"
                         :data-sqft="model.data.sqftAC">
                <div v-if="model.data.homeOfTheMonth" class="top-label home-of-the-month">
                    HOME OF THE MONTH
                </div>
                <div v-else-if="model.data.saleStatus" :class="['top-label',model.data.saleStatus.toLowerCase()]">
                    {{ model.data.saleStatus }}
                </div>
                <div class="tile-image">
                    <img :src="model.renderings[0].src" />
                    <a href="#"
                       :data-communityID="communityID"
                       :data-modelID="model.data.modelID"
                       :data-link="model.data.link"
                       data-mapEntryID="0"
                       data-favorite="1"
                       class="favorite">
                        <i class="fal fa-heart"></i>
                    </a>
                </div>
                <h2>
                    {{ model.data.name }}
                    <small v-if="inventory" style="font-weight:400">
                        {{ model.data.floorplan }} &bull; Homesite {{model.data.lot}}
                    </small>
                    <small v-if="model.data.collectionName.toString().toLowerCase() !== 'default'"
                           :style={color:model.data.collectionColor}>
                        {{ model.data.collectionName }}
                    </small>
                </h2>
                <div class="description" v-html="model.data.description"></div>
                <div class="price" v-if="priceDisplaySettings">
                    {{ inventory ? 'Price' : 'Starting Price' }} ${{ formatNumber(model.data.price) }}
                </div>
                <div class="custom-btn">
                    View {{ slug === 'floorplans' ? 'Plan' : 'Home' }}
                </div>
                <div class="sq-ft">
                    {{ formatNumber(model.data.sqftTotal) }} Total Sq. Ft. |
                    {{ formatNumber(model.data.sqftAC) }} A/C Sq. Ft.
                </div>
            </router-link>
            <div v-show="search.visible === 0" class="no-results">
                Your selections did not return any results.
            </div>
        </div>
    </div>
</template>

<script>
    import PageHeader from "@/components/PageHeader.vue";
    import Favorites from "@/plugins/Favorites.js";

    export default {
        components: {
            PageHeader
        },
        data() {
            return {
                keys : ["bedrooms","collection","sqft"],
                search: {
                    bedrooms    : 0,
                    collection  : 0,
                    sqft        : 0,
                    visible     : 0
                },
                didEnter        : false,
                inSearch        : false,
                bedroom_options : [
                    { value : 0, label : "ANY" },
                    { value : 2, label : "2" },
                    { value : 3, label : "3" },
                    { value : 4, label : "4" },
                    { value : 5, label : "5+" }
                ],
                sqft_options : [
                    { value : 0, label : "ANY" },
                    { value : 1000, label: 1000 },
                    { value : 1500, label: 1500 },
                    { value : 2000, label: 2000 },
                    { value : 2500, label: 2500 },
                    { value : 3000, label: 3000 },
                    { value : 3500, label: 3500 },
                    { value : 4000, label: 4000 },
                    { value : 5000, label: 5000 }
                ]
            };
        },
        props: {
            slug: {
                type: String,
                required: true,
                default: ""
            }
        },
        computed: {
            headerHeading(){
                return this.slug === "floorplans" ? "Floorplans" : "Move in Ready Homes";
            },
            inventory(){
                return this.slug === "inventory";
            },
            collections(){
                return this.$store.state.kiosk[this.slug].collections;
            },
            models(){
                return this.$store.state.kiosk[this.slug].records;
            },
            totals(){
                return `${this.search.visible} ${this.slug === "floorplans" ? "Floorplan" : "Home"}${this.search.visible === 1 ? "" : "s"}`;
            },
            communityID(){
                return this.$store.state.kiosk.communityID;
            },
            priceDisplaySettings(){
                let value = this.$store.state.kiosk.priceDisplaySettings ?
                    this.$store.state.kiosk.priceDisplaySettings[this.slug] :
                    true;
                return value === true || value === "true";
            }
        },
        methods: {
            modelUrl( value ) {
                return this.slug + "/" + value;
            },
            formatNumber( value ){
                return new Intl.NumberFormat().format( value );
            },
            filterChange( setState ) {
                const tiles = this.$refs.tiles.querySelectorAll( ".tile" );

                let ttl = 0,
                    visible = 0;

                // go thru each and set our targets and add up total for
                // inSearch validation
                this.keys.forEach( ( key ) => {
                    const elem = this.$refs[key];
                    if ( elem ){
                        this.search[elem.dataset.target] = parseInt( elem.value );
                        ttl += elem.value;
                    }
                } );

                // set if we are in search
                this.inSearch = ttl > 0;

                // reset tiles
                tiles.forEach( ( tile ) => {
                    if (
                        this.inSearch === 0 ||
                        (
                            ( this.search.collection === 0 || this.search.collection === parseInt( tile.dataset.collection ) ) &&
                            ( parseInt( tile.dataset.maxbedrooms ) >= this.search.bedrooms || parseInt( tile.dataset.bedrooms ) >= this.search.bedrooms ) &&
                            parseInt( tile.dataset.sqft ) >= this.search.sqft
                        )
                    ){
                        tile.classList.remove( "off" );
                        visible++;
                    }
                    else {
                        tile.classList.add( "off" );
                    }
                } );
                // update the visible count ( allow for update )
                this.search.visible = visible;
                // set state for use in views
                if ( setState ){
                    const data = {};
                    data[this.slug] = {...this.search};
                    this.$store.commit( "SET_SEARCH_PARAMS", data );
                }
            },
            resetView( setState ) {
                this.keys.forEach( ( key ) => {
                    const elem = this.$refs[key];
                    if ( elem )
                        elem.value = 0;
                } );
                this.filterChange( setState );
            },
            saveFavorite( event ){
                Favorites.saveFavorite( event );
            },
            setViewFromState() {
                const filter = this.$store.state.searchParams[this.slug];
                if ( filter ){
                    this.keys.forEach( ( key ) => {
                        const elem = this.$refs[key];
                        if ( elem )
                            elem.value = filter[key];
                    } );
                }
            }
        },
        beforeRouteEnter( to, from, next ) {
            next( vm => {
                vm.setViewFromState();
                vm.filterChange( true );
                Favorites.checkFavorites( vm.$refs.tiles );
                vm.didEnter = true;
            } );
        },
        beforeRouteLeave( to ) {
            // reset search view
            if ( ["MoveInReady","Floorplans"].indexOf( to.name ) !== -1 )
                this.resetView( false );
            const pathCheck = {
                "inventory" : "move-in-ready",
                "floorplans" : "floorplans"
            };
            if ( to.path.indexOf( pathCheck[this.slug] ) !== -1 )
                document.getElementById( "app" ).classList.add( this.slug );
        },
        mounted() {
            if ( !this.didEnter ){
                this.setViewFromState();
                this.filterChange( true );
                Favorites.checkFavorites( this.$refs.tiles );
            }
        }
    };
</script>
